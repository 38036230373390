import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  InputLabel,
  makeStyles,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  SwitchProps,
  styled,
  CircularProgress,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { UserTypeContext } from "../../../UserTypeContext";
import {
  DayEnum,
  DiscountInput,
  UserType,
  useCreateDiscountsMutation,
  WhereToRedeemEnum,
  useGetAllChamberMembersQuery,
  useGetActiveShopLocalCampaignForChamberMemberQuery,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import useGetChamberMemberFromUserIdInAuth from "../../common/Hooks/useGetChamberMemberFromUserIdInAuth";
import { v4 as uuidv4 } from "uuid";
import RedeemOfferForm from "../../common/utilities/RedeemOffer";
import useRedirect from "../../common/Hooks/useRedirect";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import DiscountOfferedToRadioGroup from "../../common/DiscountOfferedToRadioGroup";
import DiscountRedemptionOptionRadioGroup from "../../common/DiscountRedemptionOptionRadioGroup";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import PerkNotificationSwitch, {
  checkIfPerkNotificationIsEnabledForSelectedAvailability,
} from "../../common/PerkNotificationSwitch";
import Loader from "../../common/Loader/Loader";
import OnlyOfferDuringCertianMonthsForm from "../../common/utilities/OnlyOfferDuringCertainMonthsForm";
import ChamberMemberSelector from "./ChamberMemberSelector";
import useIsMemberProfileAndPerksBannersActiveForChamberMember from "../../common/Hooks/useCheckIfMemberProfileAndPerksBannersActiveForChamberMember";
import BannerPicker from "../../common/BannerPicker";
import useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement from "../../common/Hooks/useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement";
import ChamberMemberMemberProfileAndPerksBannersAgreementModal from "../../common/ChamberMemberMemberProfileAndPerksBannersAgreementModal";
import BannerPickerAgreementOverlay from "../../common/BannerPickerAgreementOverlay";
import VideoTutorialButton from "../../common/VideoTutorialButton";
import { validWebsiteRegEx } from "../../common/utilities/regexes";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    marginBottom: 0,
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginTop: "15px",
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  notificationDatePicker: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#00000000",
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      transform: "scaleX(0)",
    },
    "& .MuiFilledInput-underline:before": {
      content: "none",
    },
    "& .MuiFilledInput-underline:after": {
      content: "none",
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: "yellow",
    },
  },
  notificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  content: {
    background: "#FFFFFF",
    // height: "1029px",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    minHeight: "85vh",
    paddingBottom: "40px",
    // [theme.breakpoints.up("md")]: {
    //   height: "1200px",
    // },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    // [theme.breakpoints.down("xs")]: {
    //   height: "1600px",
    // },
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },

  templateSubtitle: {
    textAlign: "center",
    fontSize: "2.5vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4.5vw",
    },
  },

  notificationRadioGroup: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "5px",
  },
  radio: {
    color: "#E1A731!important",
  },
  notificationCreationContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
    background: "#F2F2F6",
    alignContent: "center",
    textAlign: "center",
    width: "265px",
    borderRadius: "7px 7px 0px 0px",
    margin: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  individualNotificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    background: "#F2F2F6",
  },
  individualNotificationType: {
    fontSize: "21px",
    color: "#AEAEAE",
    marginTop: "auto",
    marginBottom: "auto",
  },
  individualNotificationDate: {
    fontSize: "21px",
    color: "#AEAEAE",
    marginTop: "auto",
    marginBottom: "auto",
  },
  individualNotificationTrashIcon: {
    fontSize: "21px",
    color: "#FFFFFF",
    backgroundColor: "#FF0000",
    height: "52px",
    width: "56px",
  },
  notificationTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  notificationContainer: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "35px",
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  toggleSwitchWithTitle: {
    display: "flex",
    margin: "auto",
    alignItems: "baseline",
    marginTop: "35px",
  },
  dayCheckbox: {
    color: "#E1A731!important",
    alignItems: "center",
    textAlign: "center",
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "700px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
}));

const ShowInAppSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));
const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const oneOfContactEmailOrContactNumberRequiredYupValidationFunction = (
  values: any
) => {
  const {
    redeemSettings: { whereToRedeem, howToRedeem },
  } = values;
  if (whereToRedeem === WhereToRedeemEnum.ContactCompany) {
    if (!howToRedeem.contactEmail && !howToRedeem.contactNumber) {
      throw new yup.ValidationError(
        "Please provide either a contact email or a contact number",
        "",
        "oneOfContactEmailOrContactNumberRequired"
      );
    }
  }

  return true;
};

const validationSchema = yup
  .object({
    title: yup.string().required("Perk title is required"),
    description: yup.string(),
    memberOnly: yup.boolean().required("Please select an option"),
    isReoccuring: yup.boolean().required("Please select an option"),
    daysAvailable: yup.lazy((value) => {
      switch (typeof value) {
        case "undefined":
          return yup.mixed();
        default:
          return yup.array().min(1, "Select at least one day");
      }
    }),
    monthsAvailable: yup.lazy((value) => {
      switch (typeof value) {
        case "undefined":
          return yup.mixed();
        default:
          return yup.array().min(1, "Select at least one month");
      }
    }),
    redeemSettings: yup.object({
      whereToRedeem: yup.string().required("Where to redeem is required"),
      howToRedeem: yup
        .object()
        .when("whereToRedeem", {
          is: WhereToRedeemEnum.Online,
          then: yup
            .object({
              code: yup.string().required("Code is required"),
              websiteToEnterCodeAt: yup
                .string()
                .matches(validWebsiteRegEx, "Enter a valid website")
                .required("Website is required"),
            })
            .required(),
        })
        .when("whereToRedeem", {
          is: WhereToRedeemEnum.ContactCompany,
          then: yup
            .object({
              contactEmail: yup
                .string()
                .matches(emailRegex, "Enter a valid email"),
              contactNumber: yup
                .string()
                .matches(phoneRegExp, "Enter a valid phone number"),
              contactName: yup.string().required("Contact name is required"),
            })
            .required(),
        })
        .when("whereToRedeem", {
          is: WhereToRedeemEnum.InPerson,
          then: yup.object(),
        }),
    }),
  })
  .test("", "", oneOfContactEmailOrContactNumberRequiredYupValidationFunction);

const initialValues: DiscountInput = {
  id: "",
  title: "",
  description: "",
  chamberId: "",
  chamberMemberId: "",
  //@ts-ignore
  memberOnly: undefined,
  isAvailableToAllMembers: false,
  // @ts-ignore
  isReoccuring: undefined,
  daysAvailable: undefined,
  monthsAvailable: undefined,
  isPrivate: false,
  privateEmails: [],
  redeemSettings: {
    // @ts-ignore
    whereToRedeem: undefined,
    howToRedeem: {
      code: "",
      contactEmail: "",
      contactNumber: "",
      contactName: "",
      websiteToEnterCodeAt: "",
    },
  },
  startTimeUtcMilli: undefined,
  expiryTimeUtcMilli: undefined,
  notify: true,
  banner: undefined,
};

const paddingLeftPx = 33;

const ChamberMemberCreateDiscount = (): ReactElement => {
  const classes = useStyles();
  const CHARACTER_LIMIT_TITLE = 50;
  const CHARACTER_LIMIT_DESCRIPTION = 250;

  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const [
    chamberMemberIdSelectedForChamberAdminDiscountCreation,
    setChamberMemberIdSelectedForChamberAdminDiscountCreation,
  ] = useState(null);

  const userIdInAuthChamberMemberInfo = useGetChamberMemberFromUserIdInAuth();
  const userIdInAuthChamberInfo = useGetChamberFromUserIdInAuth();

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const { data: allChamberMemberData } = useGetAllChamberMembersQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip: !chamberInfo?.id || userType !== UserType.ChamberAdmin,
    fetchPolicy: "cache-and-network",
  });

  const chamberMemberInfo = useMemo(() => {
    if (userType === UserType.ChamberAdmin) {
      // loop through allChamberMemberData.getAllChamberMembers and find the chamber member with the id of chamberMemberIdSelectedForChamberAdminDiscountCreation
      if (
        chamberMemberIdSelectedForChamberAdminDiscountCreation &&
        allChamberMemberData?.getAllChamberMembers
      ) {
        for (const chamberMember of allChamberMemberData.getAllChamberMembers) {
          if (
            chamberMember.id ===
            chamberMemberIdSelectedForChamberAdminDiscountCreation
          ) {
            return chamberMember;
          }
        }
      }
      return undefined;
    }
    return userIdInAuthChamberMemberInfo;
  }, [
    allChamberMemberData?.getAllChamberMembers,
    chamberMemberIdSelectedForChamberAdminDiscountCreation,
    userIdInAuthChamberMemberInfo,
    userType,
  ]);

  const { data: activeShopLocalCampaignForChamberMemberData } =
    useGetActiveShopLocalCampaignForChamberMemberQuery({
      skip: userType !== UserType.ChamberMemberAdmin,
    });

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (userType === UserType.ChamberAdmin) {
      if (allChamberMemberData?.getAllChamberMembers) {
        setShowLoader(false);
      }
      return;
    }

    // handle the case where chamber member info is still loading for chamber MEMBER discount creation
    if (chamberMemberInfo && activeShopLocalCampaignForChamberMemberData) {
      setShowLoader(false);
    }
  }, [
    activeShopLocalCampaignForChamberMemberData,
    allChamberMemberData?.getAllChamberMembers,
    chamberMemberInfo,
    userType,
  ]);

  const [isDayToggleChecked, setDayToggle] = useState(false);
  const [isMonthToggleChecked, setMonthToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customError, setCustomError] = useState(
    "An Error Occured, Please try again."
  );
  const [invalidError, setInvalidError] = useState(false);
  const [createDiscount] = useCreateDiscountsMutation();
  const [createButtonText, setCreateButtonText] = useState("Create");

  const redirectToDiscountList = useRedirect(
    userType === UserType.ChamberAdmin
      ? RedirectUri.memberDiscountPageChamberAdmin
      : RedirectUri.manageDiscountChamberMemberAdmin,
    { showToast: true }
  );

  const [
    activeShopLocalCampaignModalOpen,
    setActiveShopLocalCampaignModalOpen,
  ] = useState(false);

  const id = uuidv4();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: DiscountInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);

        const isPerkNotificationEnabledForSelectedAvailability =
          checkIfPerkNotificationIsEnabledForSelectedAvailability({
            daysAvailable: input.daysAvailable,
            monthsAvailable: input.monthsAvailable,
            startTimeUtcMilli: input.startTimeUtcMilli,
            expiryTimeUtcMilli: input.expiryTimeUtcMilli,
          });

        const discountCreation = await createDiscount({
          variables: {
            input: {
              data: [
                {
                  chamberId: chamberMemberInfo?.chamberId || "",
                  chamberMemberId: chamberMemberInfo?.id || "",
                  id: id as unknown as string,
                  isReoccuring: input.isReoccuring,
                  memberOnly: input.isAvailableToAllMembers || input.memberOnly,
                  isAvailableToAllMembers: input.isAvailableToAllMembers,
                  title: input.title.trim(),
                  daysAvailable: input.daysAvailable || undefined,
                  description:
                    input.description && typeof input.description === "string"
                      ? input.description.trim()
                      : undefined,
                  monthsAvailable: input.monthsAvailable || undefined,
                  isPrivate: input.isPrivate,
                  privateEmails: input.isPrivate
                    ? input.privateEmails || []
                    : undefined,
                  redeemSettings: {
                    whereToRedeem: input.redeemSettings.whereToRedeem,
                    howToRedeem: input.redeemSettings.howToRedeem || undefined,
                  },
                  // startTimeUtcMilli & expiryTimeUtcMilli can only be set if BOTH input.startTimeUtcMilli and input.expiryTimeUtcMilli are given
                  startTimeUtcMilli:
                    input.startTimeUtcMilli && input.expiryTimeUtcMilli
                      ? input.startTimeUtcMilli
                      : undefined,
                  expiryTimeUtcMilli:
                    input.startTimeUtcMilli && input.expiryTimeUtcMilli
                      ? input.expiryTimeUtcMilli
                      : undefined,
                  notify: !isPerkNotificationEnabledForSelectedAvailability
                    ? false
                    : input.notify || false,
                  banner: input.banner || undefined,
                },
              ],
            },
          },
          refetchQueries: [
            "getAllDiscountsFromChamberMemberId",
            "getAllDiscountsFromChamberId",
          ],
        });
        const createionResult = discountCreation.data?.createDiscounts;
        if (createionResult?.discountsAddedSuccessfully === false) {
          setInvalidError(true);
          setCustomError(`Unable to create perk`);
          setCreateButtonText("Create");
          setLoader(false);
          setButtonDisabled(false);
          return;
        }
        setCreateButtonText("Created");
        setLoader(false);

        if (
          userType === UserType.ChamberMemberAdmin &&
          activeShopLocalCampaignForChamberMemberData?.getActiveShopLocalCampaignForChamber &&
          Date.now() >
            (activeShopLocalCampaignForChamberMemberData
              ?.getActiveShopLocalCampaignForChamber?.startDateUtcMilli || 0)
        ) {
          setActiveShopLocalCampaignModalOpen(true);
        } else {
          redirectToDiscountList();
        }
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
        setCreateButtonText("Error");
      }
    },
  });

  const handleDayToggleSwitch = () => {
    if (!isDayToggleChecked === false) {
      formik.setFieldValue("daysAvailable", undefined);
    }
    setDayToggle(!isDayToggleChecked);
  };

  const handleDaySelection = (isChecked: boolean, day: DayEnum) => {
    const daysInFormik = formik.values.daysAvailable || [];
    // selected
    if (isChecked) {
      daysInFormik.push(day);
    }
    if (!isChecked) {
      daysInFormik.splice(daysInFormik.indexOf(day), 1);
    }
    formik.setFieldValue("daysAvailable", daysInFormik);
  };

  // reset banner when new chamber member selected
  useEffect(() => {
    formik.setFieldValue("banner", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chamberMemberIdSelectedForChamberAdminDiscountCreation]);

  const isMemberProfileAndPerksBannersActiveForChamberMember =
    useIsMemberProfileAndPerksBannersActiveForChamberMember({
      activeMemberProfileAndPerksBannersSettings:
        chamberMemberInfo?.activeMemberProfileAndPerksBannersSettings,
    });

  const {
    handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement,
    chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
  } = useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement({
    activeMemberProfileAndPerksBannersSettings:
      chamberMemberInfo?.activeMemberProfileAndPerksBannersSettings,
  });

  const [
    isMemberProfileAndPerksBannersAgreementModalOpen,
    setIsMemberProfileAndPerksBannersAgreementModalOpen,
  ] = useState(false);

  const openChamberMemberMemberProfileAndPerksBannersAgreementModal =
    useCallback(() => {
      setIsMemberProfileAndPerksBannersAgreementModalOpen(true);
    }, []);

  const rootAdminObj = useMemo(() => {
    if (userType === UserType.ChamberAdmin) {
      return enumStrings.groups[userType][
        RedirectUri.memberDiscountCreatePageChamberAdmin
      ];
    }
    return enumStrings?.groups[UserType.ChamberMemberAdmin][
      RedirectUri.createDiscountChamberMemberAdmin
    ];
  }, [userType]);

  return (
    <span>
      <ChamberMemberMemberProfileAndPerksBannersAgreementModal
        open={isMemberProfileAndPerksBannersAgreementModalOpen}
        handleClose={() =>
          setIsMemberProfileAndPerksBannersAgreementModalOpen(false)
        }
        handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement={
          handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement
        }
        chamberName={chamberInfo?.name || ""}
      />
      {activeShopLocalCampaignModalOpen && (
        <Modal
          className={classes.modal}
          open={activeShopLocalCampaignModalOpen}
        >
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "430px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>Shop Local Contest Active</h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "17px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              Perk created successfully.
              <br />
              <br />
              {`Your company is participating in a Shop Local Contest created by ${chamberInfo?.name}.`}
              <br />
              <br />
              Chamber Perks App™ users can enter the prize draw to qualify for a
              chance to win{" "}
              {
                activeShopLocalCampaignForChamberMemberData
                  ?.getActiveShopLocalCampaignForChamber?.prizeDescription
              }{" "}
              by redeeming any perk offered by your company and entering the
              following participation code:{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {
                  (activeShopLocalCampaignForChamberMemberData
                    ?.getActiveShopLocalCampaignForChamber
                    ?.participatingMembers || [
                    {
                      participationCode: "",
                    },
                  ])[0].participationCode
                }
              </span>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={redirectToDiscountList}
                variant="contained"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>
            {rootAdminObj.title}{" "}
            {userType !== UserType.ChamberAdmin
              ? `for 
            ${chamberMemberInfo?.name || ""}`
              : null}
          </span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <div style={{ height: 12 }} />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
              flexWrap: "wrap",
            }}
          >
            {userType === UserType.ChamberMemberAdmin && (
              <div style={{ paddingBottom: "10px" }}>
                <VideoTutorialButton
                  label="How to Create a Perk"
                  tutorialUrl="https://share.synthesia.io/embeds/videos/64dc5a86-75aa-47cd-9a72-e8b46ec7f1fa"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        {showLoader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader />
          </div>
        ) : (
          <>
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {userType === UserType.ChamberAdmin ? (
                <>
                  <ChamberMemberSelector
                    allChamberMemberData={allChamberMemberData}
                    chamberMemberId={
                      chamberMemberIdSelectedForChamberAdminDiscountCreation
                    }
                    setChamberMemberId={
                      setChamberMemberIdSelectedForChamberAdminDiscountCreation
                    }
                  />
                  {!chamberMemberInfo ? (
                    <div
                      style={{
                        color: "#f44336",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                        textAlign: "center",
                        fontSize: "12px",
                        marginBottom: "-15px",
                      }}
                    >
                      Member company is required
                    </div>
                  ) : null}
                </>
              ) : null}
              <div className={classes.companyInputWithToolTipContainer}>
                <FormControl className={classes.notificationDescriptionInput}>
                  <TextField
                    className={classes.selectedValue}
                    maxRows={5}
                    label="Perk Title"
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        maxLength: CHARACTER_LIMIT_TITLE,
                      },
                    }}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    multiline
                    name="title"
                    id="title"
                  />
                </FormControl>
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={enumStrings.disocuntText.tooltip.title}
                    showIt
                  />
                </div>
              </div>
              <InputLabel style={{ margin: "auto" }}>
                {`${formik.values.title.length}/${CHARACTER_LIMIT_TITLE} characters remaining`}
                <FormHelperText
                  component="legend"
                  style={{
                    color: "#f44336",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    textAlign: "center",
                  }}
                >
                  {formik.errors.title}
                </FormHelperText>{" "}
              </InputLabel>{" "}
              <div className={classes.companyInputWithToolTipContainer}>
                <FormControl className={classes.notificationDescriptionInput}>
                  <TextField
                    className={classes.selectedValue}
                    maxRows={5}
                    label="Perk Description"
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        maxLength: CHARACTER_LIMIT_DESCRIPTION,
                      },
                    }}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    multiline
                    id="description"
                    name="description"
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <HoverToolTip
                    description={enumStrings.disocuntText.tooltip.description}
                    showIt
                  />
                </div>
              </div>
              <InputLabel style={{ margin: "auto" }}>
                {`${
                  (formik.values.description || "").length
                }/${CHARACTER_LIMIT_DESCRIPTION} characters remaining`}
              </InputLabel>
              {isMemberProfileAndPerksBannersActiveForChamberMember && (
                <div
                  className={classes.companyInputWithToolTipContainer}
                  style={{
                    paddingLeft: `${paddingLeftPx}px`,
                    position: "relative",
                  }}
                >
                  <BannerPickerAgreementOverlay
                    chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted={
                      chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted
                    }
                    openChamberMemberMemberProfileAndPerksBannersAgreementModal={
                      openChamberMemberMemberProfileAndPerksBannersAgreementModal
                    }
                    paddingLeftPx={paddingLeftPx}
                    userType={userType}
                  />
                  <BannerPicker
                    disabled={
                      !chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted
                    }
                    key="banner"
                    pickerType="perk"
                    formikVal={formik.values.banner}
                    formikSetFieldValue={(updatedBanner) =>
                      formik.setFieldValue("banner", updatedBanner)
                    }
                    pickerLabel={`Perk Banners`}
                  />
                  <div>
                    <HoverToolTip
                      description={`These banners will be shown when this perk is opened in the Chamber Perks App™. \n\nFor best quality of both videos and images, ${
                        enumStrings.bannerPicker.minSizeRequirementForBanner
                          .charAt(0)
                          .toLowerCase() +
                        enumStrings.bannerPicker.minSizeRequirementForBanner.slice(
                          1
                        )
                      } are recommended.\n\nThe position of each banner in Chamber Perks App™ will be the same as shown here.\n\nClick the right and left arrows under each banner to change its positioning.`}
                      showIt
                    />
                  </div>
                </div>
              )}
              <DiscountOfferedToRadioGroup
                formikSetFieldValue={formik.setFieldValue}
                formikErrors={formik.errors}
                formikValues={formik.values}
              />
              <DiscountRedemptionOptionRadioGroup
                formikSetFieldValue={formik.setFieldValue}
                formikErrors={formik.errors}
                formikValues={formik.values}
              />
              <FormGroup>
                <div className={classes.toggleSwitchWithTitle}>
                  <div
                    className={classes.companyInputWithToolTipContainer}
                    style={{ marginRight: "10px" }}
                  >
                    <h1 style={{ marginBottom: "0px", marginTop: "10px" }}>
                      {
                        enumStrings.disocuntText
                          .offersAvailableonCertainDaysText
                      }
                    </h1>
                    <ShowInAppSwitch
                      checked={isDayToggleChecked}
                      onChange={handleDayToggleSwitch}
                      id="isDayToggle"
                      name="isDayToggle"
                    />
                    <div
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <HoverToolTip
                        description={enumStrings.disocuntText.tooltip.dayToggle}
                        showIt
                      />
                    </div>
                  </div>
                </div>
                {isDayToggleChecked ? (
                  <div style={{ textAlign: "center", alignItems: "center" }}>
                    {Object.values(DayEnum).map((val) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleDaySelection(e.target.checked, val)
                              }
                              className={classes.dayCheckbox}
                            />
                          }
                          label={val}
                          labelPlacement="bottom"
                        />
                      );
                    })}
                  </div>
                ) : null}
              </FormGroup>
              <FormHelperText
                component="legend"
                style={{
                  color: "#f44336",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  textAlign: "center",
                }}
              >
                {formik.errors.daysAvailable}
              </FormHelperText>
              <OnlyOfferDuringCertianMonthsForm
                formikSetFieldValue={formik.setFieldValue}
                formikValuesMonthsAvailable={formik.values.monthsAvailable}
                formikErrors={formik.errors}
                isMonthToggleChecked={isMonthToggleChecked}
                setMonthToggle={setMonthToggle}
                formikValuesExpiryTimeUtcMilli={
                  formik.values.expiryTimeUtcMilli
                }
                formikValuesStartTimeUtcMilli={formik.values.startTimeUtcMilli}
              />
              <RedeemOfferForm
                formikSetFieldValue={formik.setFieldValue}
                formikVal={formik.values.redeemSettings}
                formikErrors={formik.errors}
              />
              <PerkNotificationSwitch
                isCreate
                chamberName={userIdInAuthChamberInfo?.name || ""}
                daysAvailable={formik.values.daysAvailable}
                monthsAvailable={formik.values.monthsAvailable}
                startTimeUtcMilli={formik.values.startTimeUtcMilli}
                expiryTimeUtcMilli={formik.values.expiryTimeUtcMilli}
                formikNotify={formik.values.notify}
                formikSetFieldValue={formik.setFieldValue}
              />
              <br />
              <br />
              <div>
                {invalidError ? (
                  <div className={classes.invalidError}>{customError}</div>
                ) : null}
              </div>{" "}
              {Object.keys(formik?.errors || {}).length > 0 ? (
                <div>
                  <div className={classes.invalidError}>
                    Please address any issues in red to create
                  </div>
                  <br />
                </div>
              ) : null}
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#E1A731",
                  fontSize: "23px",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  width: "200px",
                  marginTop: "59px",
                  marginBottom: "39px",
                  margin: "auto",
                  height: "65px",
                }}
                disabled={buttonDisabled || loader || !chamberMemberInfo}
              >
                {loader ? <CircularProgress /> : createButtonText}
              </Button>
            </form>
          </>
        )}
      </div>
    </span>
  );
};

export default ChamberMemberCreateDiscount;
