import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Button,
  Chip,
  CircularProgress,
  makeStyles,
  Modal,
  styled,
  Switch,
  SwitchProps,
} from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  ChamberEngageSettingsReturn,
  ChamberReturn,
  GetAllChambersForRootAdminChamberEngageDocument,
  useGetAllChambersForRootAdminChamberEngageQuery,
  UserType,
  useUpdateChamberEngageSettingsForChamberMutation,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import { getApolloContext } from "@apollo/client";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LogoImage from "../../common/utilities/LogoImage";
import EditIcon from "@material-ui/icons/Edit";
import {
  CurrencyNumberFormat,
  MonthNumberFormat,
  PercentNumberFormat,
} from "../../common/utilities/CustomNumberFormat";
import omitDeep from "omit-deep-lodash";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    marginTop: "33px",
    flexDirection: "column",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  parentOfEditIcon: {
    "&:hover $editIcon": {
      color: "#E1A731",
    },
  },
  editIcon: {
    color: "#9C9C9C",
    transition: "color 0.1s ease",
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "100%",
    borderRadius: "5px",
    textAlign: "center",
    maxHeight: "825px",
    width: "740px",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  yesButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  okButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#37474f",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
}));
type SingleObj = ChamberReturn & { chamberEngageIsActive: boolean };
export type AllTableChamberEngageDataType = SingleObj[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableChamberEngageDataType,
  comparator: (a: any, b: any) => number
): AllTableChamberEngageDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  defaultSort?: boolean;
  sortOrder?: Order;
}

interface FullPageChamberEngageTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageChamberEngageTableHead(
  props: FullPageChamberEngageTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells: HeadCell[] = [
  {
    id: "chamberLogo",
    label: "",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "memberProfileAndPerkBannerActivationFeeInDollars",
    label: "CPA Member Profile & Perk Banner Activation Fee",
  },
  {
    id: "memberProfileAndPerkBannerAnnualBannerFeeInDollars",
    label: "Member Profile & Perk Banner Annual Fee",
  },
  {
    id: "categoryAdvertisingBannersActivationFeeCpaSplitPercentage",
    label: "CPA Category Advertising Banner Revenue Share",
  },
  {
    id: "categoryAdvertisingBannersActivationFeeOptions",
    label: "Category Advertising Banners Fee Options",
  },
  {
    id: "chamberEngageIsActive",
    label: "Status",
    defaultSort: true,
    sortOrder: "desc",
  },
  {
    id: "buttonnss",
    label: "",
  },
];

const ShowInAppSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));

function formatYearMonthDuration(months: number): string {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years === 0) {
    return `${months} Month${months !== 1 ? "s" : ""}`;
  } else if (remainingMonths === 0) {
    return `${years} Year${years !== 1 ? "s" : ""}`;
  } else {
    return `${years} Year${
      years !== 1 ? "s" : ""
    } and ${remainingMonths} Month${remainingMonths !== 1 ? "s" : ""}`;
  }
}

function FullPageChamberEngageTable({
  allChamberEngageData,
}: {
  allChamberEngageData: AllTableChamberEngageDataType;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");

  React.useEffect(() => {
    for (const cell of headCells) {
      if (cell.defaultSort) {
        setOrderBy(cell.id);
        if (cell.sortOrder) {
          setOrder(cell.sortOrder);
        }
        break;
      }
    }

    return;
    // @ts-ignore
    // eslint-disable-next-line
  }, []); // this is left empty to ensure it ONLY runs on table mount and only then and never again

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [searchInput, setSearchInput] = React.useState("");
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const allTableChamberEngageData: AllTableChamberEngageDataType =
    React.useMemo(() => {
      if (!allChamberEngageData) {
        return [];
      }

      const allChamberEngageArr = allChamberEngageData.slice();

      if (searchInput.length > 0) {
        return allChamberEngageData.filter((i) => {
          return i.name.toLowerCase().match(searchInput.toLowerCase());
        });
      }

      return allChamberEngageArr;
    }, [allChamberEngageData, searchInput]);

  const visibleRows = React.useMemo(
    () => stableSort(allTableChamberEngageData, getComparator(order, orderBy)),
    [allTableChamberEngageData, order, orderBy]
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updateModalChamberId, setUpdateModalChamberId] = useState<string>("");
  const [updateModalChamberName, setUpdateModalChamberName] =
    useState<string>("");
  const [
    updateModalChamberEngageSettings,
    setUpdateModalChamberEngageSettings,
  ] = useState<ChamberEngageSettingsReturn | null>(null);

  const handleModalOpen = useCallback(
    ({
      chamberId,
      chamberName,
      chamberEngageSettings,
    }: {
      chamberId: string;
      chamberName: string;
      chamberEngageSettings: ChamberEngageSettingsReturn;
    }) => {
      let chamberEngageSettingsToSet = omitDeep(
        chamberEngageSettings,
        "__typename"
      ) as ChamberEngageSettingsReturn;

      chamberEngageSettingsToSet = {
        ...chamberEngageSettingsToSet,
        categoryAdvertisingBannersFeeOptions:
          chamberEngageSettingsToSet.categoryAdvertisingBannersFeeOptions.sort(
            (a, b) => a.timeLengthInMonths - b.timeLengthInMonths
          ),
      };

      setUpdateModalChamberId(chamberId);
      setUpdateModalChamberName(chamberName);
      setUpdateModalChamberEngageSettings(chamberEngageSettingsToSet);
      setModalIsOpen(true);
    },
    []
  );

  const handleModalClose = useCallback(() => {
    setModalIsOpen(false);
    setUpdateModalChamberId("");
    setUpdateModalChamberName("");
    setUpdateModalChamberEngageSettings(null);
  }, []);

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [updateChamberEngageSettingsForChamber] =
    useUpdateChamberEngageSettingsForChamberMutation();

  const handleChamberSettingsUpdate = useCallback(async () => {
    setUpdateInProgress(true);

    if (updateModalChamberEngageSettings) {
      await updateChamberEngageSettingsForChamber({
        variables: {
          chamberId: updateModalChamberId,
          chamberEngageSettings: updateModalChamberEngageSettings,
        },
        refetchQueries: [
          { query: GetAllChambersForRootAdminChamberEngageDocument },
          "getAllChambersForRootAdminChamberEngage",
        ],
        awaitRefetchQueries: true,
      });
    }

    handleModalClose();
    setUpdateInProgress(false);
    toast.success("Chamber Engage Settings Updated Successfully");
  }, [
    handleModalClose,
    updateChamberEngageSettingsForChamber,
    updateModalChamberEngageSettings,
    updateModalChamberId,
  ]);

  const history = useHistory();

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Modal className={classes.modal} open={modalIsOpen}>
        <div
          className={classes.paper}
          style={{ justifyContent: "space-between" }}
        >
          <h1 style={{ fontWeight: "bold" }}>
            Update {updateModalChamberName} Chamber Engage Settings
          </h1>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflow: "scroll",
              alignItems: "center",
              paddingRight: "25px",
              paddingLeft: "25px",
              paddingTop: "20px",
            }}
          >
            <CurrencyNumberFormat
              inputValue={
                updateModalChamberEngageSettings?.memberProfileAndPerkBannerActivationFeeInDollars ||
                0
              }
              errorString={""}
              showError={false}
              handleChange={(e: { target: { value: string } }) => {
                let numVal = parseFloat(e.target.value);
                if (isNaN(numVal)) {
                  // If parsing as a float results in NaN, try parsing as an integer
                  numVal = parseInt(e.target.value);
                }

                if (!isNaN(numVal) && updateModalChamberEngageSettings) {
                  // set updated value in state
                  setUpdateModalChamberEngageSettings(
                    (prev) =>
                      prev && {
                        ...prev,
                        memberProfileAndPerkBannerActivationFeeInDollars:
                          numVal,
                      }
                  );
                  return;
                }
              }}
              label={"CPA Member Profile & Perk Banner Activation Fee"}
            />
            <div style={{ height: 10 }} />
            <CurrencyNumberFormat
              inputValue={
                updateModalChamberEngageSettings?.memberProfileAndPerkBannerAnnualBannerFeeInDollars ||
                0
              }
              errorString={""}
              showError={false}
              handleChange={(e: { target: { value: string } }) => {
                let numVal = parseFloat(e.target.value);
                if (isNaN(numVal)) {
                  // If parsing as a float results in NaN, try parsing as an integer
                  numVal = parseInt(e.target.value);
                }

                if (!isNaN(numVal) && updateModalChamberEngageSettings) {
                  // set updated value in state
                  setUpdateModalChamberEngageSettings(
                    (prev) =>
                      prev && {
                        ...prev,
                        memberProfileAndPerkBannerAnnualBannerFeeInDollars:
                          numVal,
                      }
                  );
                  return;
                }
              }}
              label={"Member Profile & Perk Banner Annual Fee"}
            />
            <div style={{ height: 10 }} />
            <PercentNumberFormat
              inputValue={
                updateModalChamberEngageSettings?.categoryAdvertisingBannersActivationFeeCpaSplitPercentage ||
                0
              }
              errorString={""}
              showError={false}
              handleChange={(e: { target: { value: string } }) => {
                let numVal = parseFloat(e.target.value);
                if (isNaN(numVal)) {
                  // If parsing as a float results in NaN, try parsing as an integer
                  numVal = parseInt(e.target.value);
                }

                if (!isNaN(numVal) && updateModalChamberEngageSettings) {
                  // set updated value in state
                  setUpdateModalChamberEngageSettings(
                    (prev) =>
                      prev && {
                        ...prev,
                        categoryAdvertisingBannersActivationFeeCpaSplitPercentage:
                          numVal,
                      }
                  );
                  return;
                }
              }}
              label={"CPA Category Advertising Banner Revenue Share"}
            />
            <div style={{ height: 30 }} />
            <h1 style={{ marginBottom: "0px", marginTop: "0px" }}>
              Category Advertising Banners Fee Options
            </h1>
            {/* loop through updateModalChamberEngageSettings?.updateModalChamberEngageSettings and create a row for each object in array that shows the following elements: an input for number of months, an input for banner fee and a button to delete this object from the array */}
            {(
              updateModalChamberEngageSettings?.categoryAdvertisingBannersFeeOptions ||
              []
            ).map((option, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3 style={{ margin: 0, fontWeight: "bold" }}>
                      Option {index + 1}
                    </h3>
                    <div style={{ width: 10 }} />
                    <MonthNumberFormat
                      minWidth
                      inputValue={option.timeLengthInMonths}
                      errorString={""}
                      showError={false}
                      handleChange={(e: { target: { value: string } }) => {
                        let numVal = parseInt(e.target.value);
                        if (
                          !isNaN(numVal) &&
                          updateModalChamberEngageSettings
                        ) {
                          // set updated value in state
                          setUpdateModalChamberEngageSettings(
                            (prev) =>
                              prev && {
                                ...prev,
                                categoryAdvertisingBannersFeeOptions: [
                                  ...prev.categoryAdvertisingBannersFeeOptions.slice(
                                    0,
                                    index
                                  ),
                                  {
                                    ...prev
                                      .categoryAdvertisingBannersFeeOptions[
                                      index
                                    ],
                                    timeLengthInMonths: numVal,
                                  },
                                  ...prev.categoryAdvertisingBannersFeeOptions.slice(
                                    index + 1
                                  ),
                                ],
                              }
                          );
                          return;
                        }
                      }}
                      label={"Months"}
                    />
                    <div style={{ width: 10 }} />
                    <CurrencyNumberFormat
                      minWidth
                      inputValue={option.bannerFeeInDollars}
                      errorString={""}
                      showError={false}
                      handleChange={(e: { target: { value: string } }) => {
                        let numVal = parseFloat(e.target.value);
                        if (isNaN(numVal)) {
                          // If parsing as a float results in NaN, try parsing as an integer
                          numVal = parseInt(e.target.value);
                        }

                        if (
                          !isNaN(numVal) &&
                          updateModalChamberEngageSettings
                        ) {
                          // set updated value in state
                          setUpdateModalChamberEngageSettings(
                            (prev) =>
                              prev && {
                                ...prev,
                                categoryAdvertisingBannersFeeOptions: [
                                  ...prev.categoryAdvertisingBannersFeeOptions.slice(
                                    0,
                                    index
                                  ),
                                  {
                                    ...prev
                                      .categoryAdvertisingBannersFeeOptions[
                                      index
                                    ],
                                    bannerFeeInDollars: numVal,
                                  },
                                  ...prev.categoryAdvertisingBannersFeeOptions.slice(
                                    index + 1
                                  ),
                                ],
                              }
                          );
                          return;
                        }
                      }}
                      label={"Fee"}
                    />
                  </div>
                  <div style={{ width: 10 }} />
                  <DeleteIcon
                    onClick={() => {
                      setUpdateModalChamberEngageSettings(
                        (prev) =>
                          prev && {
                            ...prev,
                            categoryAdvertisingBannersFeeOptions: [
                              ...prev.categoryAdvertisingBannersFeeOptions.slice(
                                0,
                                index
                              ),
                              ...prev.categoryAdvertisingBannersFeeOptions.slice(
                                index + 1
                              ),
                            ],
                          }
                      );
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#37474f",
                      fontSize: 30,
                      margin: 0,
                    }}
                  />
                </div>
              );
            })}
            <div style={{ height: 15 }} />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#37474f",
                color: "#e9e9e9",
                fontWeight: "bold",
              }}
              onClick={() => {
                setUpdateModalChamberEngageSettings(
                  (prev) =>
                    prev && {
                      ...prev,
                      categoryAdvertisingBannersFeeOptions: [
                        ...prev.categoryAdvertisingBannersFeeOptions,
                        {
                          timeLengthInMonths: 0,
                          bannerFeeInDollars: 0,
                        },
                      ],
                    }
                );
              }}
            >
              Add Option
            </Button>

            <div style={{ height: 30 }} />
            <h1 style={{ marginBottom: "-10px", marginTop: "0px" }}>
              Activate Chamber Engage
            </h1>
            <ShowInAppSwitch
              checked={updateModalChamberEngageSettings?.isActive || false}
              onChange={(e: { target: { checked: boolean } }) => {
                if (updateModalChamberEngageSettings) {
                  setUpdateModalChamberEngageSettings(
                    (prev) =>
                      prev && {
                        ...prev,
                        isActive: e.target.checked,
                      }
                  );
                }
              }}
              id="isDayToggle"
              name="isDayToggle"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              className={classes.yesButton}
              onClick={handleChamberSettingsUpdate}
              variant="contained"
              disabled={updateInProgress}
            >
              {updateInProgress ? (
                <CircularProgress
                  size={30}
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                "Update"
              )}
            </Button>
            <Button
              className={classes.okButton}
              onClick={handleModalClose}
              variant="contained"
              disabled={updateInProgress}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <div className={classes.searchContainer}>
        <TextField
          style={{
            borderBottom: "none",
            border: "1px solid black",
          }}
          type="text"
          placeholder="Search by Chamber or Board Name"
          onChange={handleChange}
          value={searchInput}
          className={classes.searchBar}
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
          }}
        />
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageChamberEngageTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((chamberInfo) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={chamberInfo.id}
                    sx={{ cursor: "unset" }}
                  >
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      <LogoImage
                        logoSource={chamberInfo.logo}
                        size="large"
                        squared
                        key={chamberInfo.id}
                        chamberName={chamberInfo.name}
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {chamberInfo.name}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", cursor: "pointer" }}
                      component="th"
                      scope="row"
                      className={classes.parentOfEditIcon}
                      onClick={() =>
                        handleModalOpen({
                          chamberId: chamberInfo.id,
                          chamberName: chamberInfo.name,
                          chamberEngageSettings:
                            chamberInfo.chamberEngageSettings,
                        })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        $
                        {
                          chamberInfo.chamberEngageSettings
                            .memberProfileAndPerkBannerActivationFeeInDollars
                        }
                        <div style={{ width: 10 }} />
                        <EditIcon
                          style={{
                            fontSize: 25,
                            margin: 0,
                          }}
                          className={classes.editIcon}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      component="th"
                      scope="row"
                      className={classes.parentOfEditIcon}
                      onClick={() =>
                        handleModalOpen({
                          chamberId: chamberInfo.id,
                          chamberName: chamberInfo.name,
                          chamberEngageSettings:
                            chamberInfo.chamberEngageSettings,
                        })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          label={`$${chamberInfo.chamberEngageSettings.memberProfileAndPerkBannerAnnualBannerFeeInDollars}`}
                          style={{
                            backgroundColor: "#E1A731",
                            color: "white",
                            fontWeight: "bold",
                            borderColor: "#E1A731",
                          }}
                        />
                        <div style={{ width: 10 }} />
                        <EditIcon
                          style={{
                            fontSize: 25,
                            margin: 0,
                          }}
                          className={classes.editIcon}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", cursor: "pointer" }}
                      component="th"
                      scope="row"
                      className={classes.parentOfEditIcon}
                      onClick={() =>
                        handleModalOpen({
                          chamberId: chamberInfo.id,
                          chamberName: chamberInfo.name,
                          chamberEngageSettings:
                            chamberInfo.chamberEngageSettings,
                        })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {
                          chamberInfo.chamberEngageSettings
                            .categoryAdvertisingBannersActivationFeeCpaSplitPercentage
                        }{" "}
                        %
                        <div style={{ width: 10 }} />
                        <EditIcon
                          style={{
                            fontSize: 25,
                            margin: 0,
                          }}
                          className={classes.editIcon}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", cursor: "pointer" }}
                      component="th"
                      scope="row"
                      className={classes.parentOfEditIcon}
                      onClick={() =>
                        handleModalOpen({
                          chamberId: chamberInfo.id,
                          chamberName: chamberInfo.name,
                          chamberEngageSettings:
                            chamberInfo.chamberEngageSettings,
                        })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {[
                            // eslint-disable-next-line
                            // @ts-ignore
                            ...new Set(
                              chamberInfo.chamberEngageSettings.categoryAdvertisingBannersFeeOptions
                            ),
                          ]
                            .sort(
                              (a, b) =>
                                a.timeLengthInMonths - b.timeLengthInMonths
                            )
                            .map(
                              ({ bannerFeeInDollars, timeLengthInMonths }) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginTop: 10,
                                    }}
                                  >
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      {formatYearMonthDuration(
                                        timeLengthInMonths
                                      )}
                                    </span>
                                    <div style={{ width: 10 }} />
                                    <Chip
                                      label={`$${bannerFeeInDollars}`}
                                      style={{
                                        backgroundColor: "#E1A731",
                                        color: "white",
                                        fontWeight: "bold",
                                        borderColor: "#E1A731",
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div style={{ width: 10 }} />
                        <EditIcon
                          style={{
                            fontSize: 25,
                            margin: 0,
                          }}
                          className={classes.editIcon}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      component="th"
                      scope="row"
                      className={classes.parentOfEditIcon}
                      onClick={() =>
                        handleModalOpen({
                          chamberId: chamberInfo.id,
                          chamberName: chamberInfo.name,
                          chamberEngageSettings:
                            chamberInfo.chamberEngageSettings,
                        })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          label={
                            chamberInfo.chamberEngageSettings.isActive
                              ? "Active"
                              : "Inactive"
                          }
                          variant="outlined"
                          size="medium"
                          style={{
                            cursor: "pointer",
                            color: chamberInfo.chamberEngageSettings.isActive
                              ? "#34694F"
                              : "#737373",
                            backgroundColor: chamberInfo.chamberEngageSettings
                              .isActive
                              ? "#DCEEDC"
                              : "#f2f2f2",
                            borderColor: chamberInfo.chamberEngageSettings
                              .isActive
                              ? "#34694F"
                              : "#737373",
                          }}
                        />
                        <div style={{ width: 10 }} />
                        <EditIcon
                          className={classes.editIcon}
                          style={{
                            fontSize: 25,
                            margin: 0,
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                          style={{
                            backgroundColor: "#E1A731",
                            fontSize: "12px",
                            minWidth: 200,
                            fontWeight: "bold",
                            borderRadius: "10px",
                          }}
                          disabled={!chamberInfo.chamberEngageSettings.isActive}
                          onClick={() => {
                            if (chamberInfo.chamberEngageSettings.isActive) {
                              history.push(
                                RedirectUri.chamberEngageManageCategoryAdvertisingBanners,
                                {
                                  chamberId: chamberInfo.id,
                                  chamberName: chamberInfo.name,
                                }
                              );
                            }
                          }}
                        >
                          Manage Category Advertising Banners
                        </Button>
                        <div style={{ height: 15 }} />
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                          style={{
                            backgroundColor: "#E1A731",
                            fontSize: "12px",
                            minWidth: 200,
                            fontWeight: "bold",
                            borderRadius: "10px",
                            whiteSpace: "pre-wrap",
                          }}
                          disabled={!chamberInfo.chamberEngageSettings.isActive}
                          onClick={() => {
                            if (chamberInfo.chamberEngageSettings.isActive) {
                              history.push(
                                RedirectUri.chamberEngageManageMemberProfileAndPerkBanners,
                                {
                                  chamberId: chamberInfo.id,
                                  chamberName: chamberInfo.name,
                                }
                              );
                            }
                          }}
                        >
                          Manage Member{"\n"}Profile & Perk Banners
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

const RootAdminManageChamberEngage = (): ReactElement => {
  const classes = useStyles();

  let { data: allChambers, loading } =
    useGetAllChambersForRootAdminChamberEngageQuery({
      fetchPolicy: "network-only",
      variables: {
        getAllSandbox: true,
      },
    });

  const [allChamberEngageData, setAllChamberEngageData] =
    React.useState<AllTableChamberEngageDataType>([]);

  const apolloClient = React.useContext(getApolloContext());

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getChamberEngagetats = async () => {
      if (!allChambers?.getAllChambers) {
        setAllChamberEngageData([]);
        return;
      }

      const allChamberEngageArr: AllTableChamberEngageDataType = [];

      for (const chamberInfo of allChambers?.getAllChambers) {
        allChamberEngageArr.push({
          ...chamberInfo,
          chamberEngageIsActive: chamberInfo.chamberEngageSettings.isActive
            ? true
            : false,
        });
      }

      setAllChamberEngageData(
        allChamberEngageArr
          .slice()
          .sort((a, b) => (a.name > b.name ? 1 : -1)) || []
      );
      setIsLoading(false);
    };

    getChamberEngagetats();
  }, [allChambers?.getAllChambers, apolloClient.client, loading]);

  const rootAdminObj = // @ts-ignore
    enumStrings?.groups[UserType.RootAdmin][
      RedirectUri.rootManageChamberEngage
    ];

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {allChamberEngageData && allChamberEngageData.length > 0 ? (
              <FullPageChamberEngageTable
                allChamberEngageData={allChamberEngageData}
              />
            ) : (
              <div
                style={{
                  color: "black",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                Loading Chambers
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RootAdminManageChamberEngage;
