import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Auth } from "aws-amplify";
import { RedirectUri } from "./components/enumStrings";
import { setContext } from "@apollo/client/link/context";
import jwt_decode from "jwt-decode";

const uri =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const getNewAccessToken = async (): Promise<string | undefined> => {
  try {
    await Auth.currentSession();
    const token = await Auth.currentAuthenticatedUser(); // add method to get token here
    return token.signInUserSession.accessToken.jwtToken;
  } catch (e) {
    console.log("Error getting access token for apollo client:", e);
  }

  return undefined;
};

const getApolloClient = async (
  redirectToError: () => void
): Promise<ApolloClient<NormalizedCacheObject>> => {
  const errorLink = onError((e) => {
    const urlPath = window.location.pathname;
    console.log("Caught Apollo Client Error", e);
    if (urlPath !== RedirectUri.errorGeneral) {
      window.location.href = RedirectUri.errorGeneral;
    }
  });

  let accessToken = await getNewAccessToken();

  const authLink = setContext(async (operation, previousContext) => {
    // refresh token if it's expired bruv and not a guest
    if (
      accessToken &&
      // eslint-disable-next-line
      // @ts-ignore
      Date.now() >= (jwt_decode(accessToken).exp as number) * 1000
    ) {
      accessToken = await getNewAccessToken();
    }

    let apiAuthorization = accessToken ? `Bearer ${accessToken}` : "";

    // attach access token for the manual requests made during setting a permanent password while using a temporary password
    if (
      previousContext &&
      previousContext?.customAccessTokenForSettingHasSignedUp &&
      previousContext?.customAccessTokenForSettingHasSignedUp?.length > 0
    ) {
      apiAuthorization = `Bearer ${previousContext.customAccessTokenForSettingHasSignedUp}`;
    }

    return {
      ...previousContext, // ensure previous context is passed along to sustain cache and all the other apollo graphql goodies (without passing this everything breaks)
      headers: {
        authorization: apiAuthorization,
      },
    };
  });

  const httpLink = new HttpLink({
    uri,
  });

  const link = ApolloLink.from([errorLink, authLink, httpLink]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return client;
};

export default getApolloClient;
