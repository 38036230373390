import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress, IconButton } from "@material-ui/core";
import {
  LoginInfoEmailTypes,
  useGetSampleLoginInfoEmailQuery,
  useSetNewTemporaryChamberMemberPasswordMutation,
} from "../../graphql/types";
import { CSVLink } from "react-csv";
import enumStrings from "../enumStrings";

type ModalType =
  | "memberTable"
  | "changeLoginEmailUsername"
  | "resetPassword"
  | "createChamberMember";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      height: "100%",
      borderRadius: "5px",
      textAlign: "center",
      maxHeight: ({ modalType }: { modalType: ModalType }) =>
        modalType === "resetPassword"
          ? "700px"
          : modalType === "memberTable"
          ? "830px"
          : "680px",
      width: "740px",
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(4),
      position: "relative",
    },
    yesButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
    okButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
  })
);

const DownloadLoginInfoConfirmationModal = ({
  open,
  handleClose,
  memberAdminPortalEmail,
  memberCompnayName,
  newPassword,
  modalType,
}: {
  open: boolean;
  handleClose: () => void;
  memberAdminPortalEmail: string;
  memberCompnayName: string;
  newPassword: string;
  modalType: ModalType;
}): ReactElement => {
  const classes = useStyles({ modalType: modalType });

  const csvData = [
    ["Member Dashboard Email Username", "Member Dashboard Temporary Password"],
    [memberAdminPortalEmail, newPassword],
  ];

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <h2>
            Member dashboard login credentials have been automatically
            downloaded. If the download hasn't started yet, click 'Download'.
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <CSVLink
            data={csvData}
            filename={`${memberCompnayName} member dashboard credentials.csv`}
            style={{
              textDecoration: "none",
            }}
          >
            <Button className={classes.yesButton} variant="contained">
              Download
            </Button>
          </CSVLink>

          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const EmailLoginInfoConfirmationModal = ({
  open,
  handleClose,
  modalType,
}: {
  open: boolean;
  handleClose: () => void;
  modalType: ModalType;
}) => {
  const classes = useStyles({ modalType: modalType });

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <h2>Member dashboard login credential email successfully sent.</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const SampleLoginInfoEmailModal = ({
  open,
  handleClose,
  emailHtml,
  modalType,
}: {
  open: boolean;
  handleClose: () => void;
  emailHtml: null | undefined | string;
  modalType: ModalType;
}) => {
  const classes = useStyles({ modalType: modalType });

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            textAlign: "left",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
            backgroundColor: "white",
            borderRadius: "15px",
            overflow: "scroll",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: emailHtml || "" }} />
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const SendLoginInformationModal = ({
  open,
  handleClose,
  redirectToMemberProfile,
  memberCompanyName,
  memberAdminPortalEmail,
  chamberMemberId,
  type,
  credentialsSentAtUtcMilli,
  isSuspended,
}: {
  open: boolean;
  handleClose: () => void;
  redirectToMemberProfile?: () => void;
  memberCompanyName: string;
  memberAdminPortalEmail: string;
  chamberMemberId: string | null | undefined;
  type: ModalType;
  isSuspended: boolean;
  credentialsSentAtUtcMilli?: number | null | undefined;
}): ReactElement => {
  // const chamberInfo = useGetChamberFromUserIdInAuth();

  const classes = useStyles({ modalType: type });

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const [
    downloadLoginInfoConfirmationModalOpen,
    setDownloadLoginInfoConfirmationModalOpen,
  ] = useState(false);
  const closeDownloadLoginInfoConfirmationModal = useCallback(() => {
    setDownloadLoginInfoConfirmationModalOpen(false);
    handleClose();
  }, [handleClose]);

  const [
    emailLoginInfoConfirmationModalOpen,
    setEmailLoginInfoConfirmationModalOpen,
  ] = useState(false);
  const closeEmailLoginInfoConfirmationModal = useCallback(() => {
    setEmailLoginInfoConfirmationModalOpen(false);
    handleClose();
  }, [handleClose]);

  const [sampleLoginInfoEmailModalOpen, setSampleLoginInfoEmailModalOpen] =
    useState(false);
  const closeSampleLoginInfoEmailModal = useCallback(() => {
    setSampleLoginInfoEmailModalOpen(false);
  }, []);

  const [setNewTemporaryChamberMemberPassowrd] =
    useSetNewTemporaryChamberMemberPasswordMutation();

  const executeDownloadLoginInfo = useCallback(async () => {
    setLoading(true);

    const { data } = await setNewTemporaryChamberMemberPassowrd({
      variables: {
        adminConsoleLoginEmail: memberAdminPortalEmail || "",
        chamberMemberId: chamberMemberId || "",
      },
    });

    setNewPassword(data?.setNewTemporaryChamberMemberPassword.password || "");

    setDownloadLoginInfoConfirmationModalOpen(true);
    setLoading(false);
    const button = document.getElementById(
      "login-info-modal-csv-download-button"
    );
    button?.click();
  }, [
    chamberMemberId,
    memberAdminPortalEmail,
    setNewTemporaryChamberMemberPassowrd,
  ]);

  const csvData = useMemo(
    () => [
      [
        "Member Dashboard Email Username",
        "Member Dashboard Temporary Password",
      ],
      [memberAdminPortalEmail, newPassword],
    ],
    [memberAdminPortalEmail, newPassword]
  );

  // const [sendLoginInfoEmailToChamberMember] =
  //   useSendLoginInfoEmailToChamberMemberMutation();

  // const executeSendLoginInfoEmail = useCallback(async () => {
  //   setLoading(true);

  //   let apiType = LoginInfoEmailTypes.BrandNew;

  //   if (type === "changeLoginEmailUsername") {
  //     apiType = LoginInfoEmailTypes.ChangeEmailUsername;
  //   }
  //   if (type === "resetPassword") {
  //     apiType = LoginInfoEmailTypes.ResetPassword;
  //   }

  //   const { data } = await sendLoginInfoEmailToChamberMember({
  //     variables: {
  //       adminConsoleLoginEmail: memberAdminPortalEmail || "",
  //       chamberMemberId: chamberMemberId || "",
  //       type: apiType,
  //     },
  //     refetchQueries: ["getChamberMembers"],
  //   });

  //   if (
  //     data?.sendLoginInfoEmailToChamberMember?.loginInfoEmailSentSuccessfully
  //   ) {
  //     setEmailLoginInfoConfirmationModalOpen(true);
  //   } else {
  //     handleClose();
  //   }

  //   setLoading(false);
  // }, [
  //   chamberMemberId,
  //   handleClose,
  //   memberAdminPortalEmail,
  //   sendLoginInfoEmailToChamberMember,
  //   type,
  // ]);

  const sampleType: LoginInfoEmailTypes = useMemo(() => {
    if (type === "changeLoginEmailUsername") {
      return LoginInfoEmailTypes.ChangeEmailUsername;
    }
    if (type === "resetPassword") {
      return LoginInfoEmailTypes.ResetPassword;
    }
    return LoginInfoEmailTypes.BrandNew;
  }, [type]);

  const { data: getSampleLoginEmail } = useGetSampleLoginInfoEmailQuery({
    variables: {
      type: sampleType,
      chamberMemberId: chamberMemberId || "",
    },
  });

  const modalTitle = useMemo(() => {
    if (type === "memberTable") {
      if (credentialsSentAtUtcMilli) {
        return "Resend Login Credentials";
      }
      return "Send Login Credentials";
    }

    if (type === "changeLoginEmailUsername") {
      return "Change Successful!";
    }

    if (type === "resetPassword") {
      return "New Temporary Password Generated!";
    }

    if (type === "createChamberMember") {
      return enumStrings.loginInormationModal.titles.member;
    }

    return "";
  }, [credentialsSentAtUtcMilli, type]);

  // const isChamber = useIsChamber();

  return (
    <>
      <SampleLoginInfoEmailModal
        open={sampleLoginInfoEmailModalOpen}
        handleClose={closeSampleLoginInfoEmailModal}
        emailHtml={getSampleLoginEmail?.getSampleLoginInfoEmail?.emailHtml}
        modalType={type}
      />
      <DownloadLoginInfoConfirmationModal
        open={downloadLoginInfoConfirmationModalOpen}
        handleClose={closeDownloadLoginInfoConfirmationModal}
        memberAdminPortalEmail={memberAdminPortalEmail}
        memberCompnayName={memberCompanyName}
        newPassword={newPassword}
        modalType={type}
      />
      <EmailLoginInfoConfirmationModal
        open={emailLoginInfoConfirmationModalOpen}
        handleClose={closeEmailLoginInfoConfirmationModal}
        modalType={type}
      />
      <Modal
        className={classes.modal}
        style={{
          ...(sampleLoginInfoEmailModalOpen ||
          downloadLoginInfoConfirmationModalOpen ||
          emailLoginInfoConfirmationModalOpen
            ? { display: "none" }
            : {}),
        }}
        open={open}
        onClose={loading || type !== "memberTable" ? undefined : handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={classes.paper}
          style={{
            ...(isSuspended ? { height: "380px" } : { maxHeight: "730px" }),
          }}
        >
          {/* place a close button on the top right of modal */}
          <IconButton
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              color: "#37474f",
              fontWeight: "bold",
              borderRadius: "100px",
              padding: 5,
              ...(loading || type !== "memberTable" ? { display: "none" } : {}),
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {loading ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  color: "#E1A731",
                }}
              />
            </div>
          ) : (
            <>
              <h1 style={{ fontWeight: "bold" }}>{modalTitle}</h1>
              <div
                style={{
                  margin: "auto",
                  overflow: "scroll",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  ...(isSuspended ? { flex: 1 } : {}),
                }}
              >
                {isSuspended ? (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      overflow: "scroll",
                      alignItems: "center",
                      paddingRight: "25px",
                      paddingLeft: "25px",
                      paddingTop: "30px",
                      height: "100%",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <h3
                        style={{
                          textAlign: "left",
                          margin: 0,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Please note, since this member's membership status was
                        set to suspended, they will not be able to access the
                        member dashboard until their membership status is
                        changed to active.
                      </h3>
                    </div>
                    <Button
                      className={classes.yesButton}
                      onClick={handleClose}
                      variant="contained"
                    >
                      Close
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      overflow: "scroll",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "19px",
                        textAlign: "left",
                        width: "100%",
                        marginBottom: "10px",
                      }}
                    >
                      Option 1: Automatically Email{" "}
                      {type === "resetPassword"
                        ? "Password Reset Instructions"
                        : "Member Dashboard Credentials"}
                    </p>

                    <span>
                      <h3 style={{ textAlign: "left", margin: 0 }}>
                        The automatic email feature is currently being updated
                        to provide a more efficient and effective solution for
                        chambers (or boards) and will be available soon. In the
                        meantime, please use option 2 below to send out member
                        login credentials.
                      </h3>
                    </span>

                    {/* <span>
                      <h3 style={{ textAlign: "left", margin: 0 }}>
                        Click 'Send Email' and an email will be sent from{" "}
                        {type === "resetPassword"
                          ? "noreply@chamberperksapp.com"
                          : "welcome@chamberperksapp.com"}{" "}
                        to {memberCompanyName} at{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {removePlusAddress({
                            adminConsoleLoginEmail: memberAdminPortalEmail,
                            chamberName: chamberInfo?.name || "",
                          })}
                        </span>{" "}
                        with{" "}
                        {type === "resetPassword"
                          ? "instructions to set a new permanent Member Dashboard password"
                          : `their Member Dashboard login credentials containing their email username and temporary password`}
                        .
                      </h3>
                    </span>
                    <span style={{ marginTop: "10px" }}>
                      <h3 style={{ textAlign: "left", margin: 0 }}>
                        Use this option when a new member has already received
                        an onboarding email or a welcome call from your{" "}
                        {isChamber ? "chamber" : "board"}. Your email should
                        have informed them to anticipate an additional email
                        from{" "}
                        {type === "resetPassword"
                          ? "noreply@chamberperksapp.com"
                          : "welcome@chamberperksapp.com"}{" "}
                        that will contain their Member Dashboard login
                        credentials for their account. Please remind members to
                        check their spam folders.
                      </h3>
                    </span>
                    {type === "memberTable" ? (
                      <span style={{ marginTop: "10px" }}>
                        <h3
                          style={{
                            textAlign: "left",
                            margin: 0,
                            fontWeight: "bold",
                          }}
                        >
                          If the above email address is not correct for this
                          member, please update it by clicking "Change Email
                          Username" on their profile page (
                          <span
                            onClick={
                              redirectToMemberProfile
                                ? redirectToMemberProfile
                                : undefined
                            }
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            click here
                          </span>{" "}
                          to go to their profile).
                        </h3>
                      </span>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className={classes.yesButton}
                        onClick={executeSendLoginInfoEmail}
                        variant="contained"
                      >
                        Send Email
                      </Button>
                      <span
                        onClick={() => {
                          setSampleLoginInfoEmailModalOpen(true);
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginTop: "-4px",
                        }}
                      >
                        Click here to preview email
                      </span>
                    </div> */}
                    <br />
                    <br />
                    <p
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        fontSize: "19px",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      Option 2: Manually Send{" "}
                      {type === "resetPassword"
                        ? "Password Reset Instructions"
                        : "Member Dashboard Credentials"}
                    </p>
                    <span>
                      <h3 style={{ textAlign: "left", margin: 0 }}>
                        Click 'Download Credentials' to download a CSV file
                        containing {memberCompanyName}'s Member Dashboard login
                        credentials containing their email username and
                        temporary password.{" "}
                        <span
                          onClick={() => {
                            setSampleLoginInfoEmailModalOpen(true);
                          }}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Click here
                        </span>{" "}
                        to preview a template email you can use to send these
                        credentials manually to this member using your preferred
                        email method.
                      </h3>
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "-10px",
                      }}
                    >
                      <Button
                        className={classes.okButton}
                        onClick={executeDownloadLoginInfo}
                        variant="contained"
                      >
                        Download Credentials
                      </Button>
                    </div>

                    {type === "memberTable" && (
                      <>
                        <br />
                        <br />
                        <p
                          style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontSize: "19px",
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          Option 3: Mass Email
                        </p>
                        <span>
                          <h3 style={{ textAlign: "left", margin: 0 }}>
                            To mass email all login credentials for your
                            members, you can download all credentials by
                            clicking the “Download All Member Login Credentials”
                            button at the top of the page after closing this
                            popup.
                          </h3>
                        </span>
                      </>
                    )}

                    <br />
                    <br />
                    {type === "memberTable" ||
                    type === "changeLoginEmailUsername" ? (
                      <span style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                        <span style={{ fontWeight: "bold" }}>Please Note:</span>{" "}
                        Both buttons will generate a new temporary password for
                        this member's dashboard login credentials, any temporary
                        passwords previously sent to this member will no longer
                        be valid.
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
      <CSVLink
        data={csvData}
        filename={`${memberCompanyName} member dashboard credentials.csv`}
        id="login-info-modal-csv-download-button"
        style={{ display: "none" }}
      />
    </>
  );
};

export default SendLoginInformationModal;
