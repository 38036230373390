import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { Maybe, useGetAllChambersQuery } from "../../../graphql/types";
import Loader from "../Loader/Loader";
import { TextField, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../../themes";

type ChamberDataObj = { id: string; name: string };

const useStyles = makeStyles(() => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0px",
    marginTop: "10px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
}));

export default function ChooseChambers({
  selectedChamberIds,
  handleChambersChange,
}: {
  selectedChamberIds: Maybe<string[]> | undefined;
  handleChambersChange: (e: string[]) => void;
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState<readonly ChamberDataObj[]>([]);
  const [fullList, setFullList] = useState<readonly ChamberDataObj[]>([]);

  const handleToggle =
    (newCheckState: boolean, value: ChamberDataObj) => () => {
      const newChecked = [...checked];

      if (newCheckState) {
        newChecked.push(value);
      } else {
        const currentIndex = checked.findIndex((obj) => obj.id === value.id);
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
      handleChambersChange(newChecked.map((chamber) => chamber.id));
    };

  const handleToggleAll = (items: readonly ChamberDataObj[]) => () => {
    if (checked.length === items.length) {
      setChecked([]);
      handleChambersChange([]);
    } else {
      setChecked(fullList);
      handleChambersChange(fullList.map((chamber) => chamber.id));
    }
  };

  const { data } = useGetAllChambersQuery({
    variables: {
      getAllSandbox: true,
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  const [searchInput, setSearchInput] = useState("");
  const handleSearchChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    const allChamberSelectableArray = (data?.getAllChambers || [])
      .map(({ id, name }) => ({
        id,
        name,
      }))
      .sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

    if (selectedChamberIds && selectedChamberIds.length > 0) {
      setChecked(
        (allChamberSelectableArray || []).filter((chamber) =>
          selectedChamberIds.includes(chamber.id)
        ) || []
      );
      setFullList(
        (allChamberSelectableArray || []).map((chamber) => ({
          id: chamber.id,
          name: chamber.name,
        })) || []
      );
    } else {
      setChecked([]);
      setFullList(allChamberSelectableArray || []);
    }
    setIsLoading(false);
  }, [data?.getAllChambers, selectedChamberIds]);

  const searchedItems = useMemo(() => {
    return fullList.filter((chamber) =>
      chamber.name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [fullList, searchInput]);

  if (!data?.getAllChambers || isLoading) {
    return (
      <div style={{ marginTop: "46px" }}>
        <Loader />
      </div>
    );
  }

  const customList = (
    title: ReactNode,
    items: readonly ChamberDataObj[],
    checkItems: number
  ) => (
    <Card>
      <CardHeader sx={{ px: 2, py: 1 }} title={title} />
      <h3
        style={{
          marginBottom: "0px",
          paddingLeft: "20px",
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {checkItems}/{fullList.length} chambers/boards selected
      </h3>
      <ListItem
        key={"select-all-list-item"}
        role="listitem"
        button
        onClick={handleToggleAll(items)}
      >
        <ListItemIcon>
          <Checkbox
            style={{
              color: "#E1A731",
            }}
            checked={checked.length === items.length && items.length !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        </ListItemIcon>
        <ListItemText id={"select-all"} primary={"Select All"} />
      </ListItem>
      <Divider />
      <List
        sx={{
          height: 350,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;
          const isChecked = checked.some((chamber) => chamber.id === value.id);
          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(!isChecked, value)}
            >
              <ListItemIcon>
                <Checkbox
                  style={{
                    color: "#E1A731",
                  }}
                  checked={checked.some((chamber) => chamber.id === value.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid
      container
      style={{
        marginTop: "56px",
      }}
      spacing={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <h1 style={{ marginBottom: "0px", textAlign: "center" }}>
        Set Availability For Chambers/Boards
      </h1>

      <Grid
        container
        style={{
          marginTop: "10px",
        }}
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          {customList(
            <div className={classes.searchContainer}>
              <TextField
                style={{
                  borderBottom: "none",
                  border: "1px solid #9E9E9E",
                }}
                type="text"
                placeholder="Search by Chamber/Board Name"
                onChange={handleSearchChange}
                value={searchInput}
                className={classes.searchBar}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
                }}
              />
            </div>,
            searchedItems,
            checked.length
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
