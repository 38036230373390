import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Button, FormControl, makeStyles, TextField } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  ChamberInput,
  useCreateChambersMutation,
  UserType,
} from "../../../graphql/types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import useRedirect from "../../common/Hooks/useRedirect";
import {
  ButtonConfigType,
  YesNoButtonConfig,
} from "../../common/ConfirmationModal/ChamberInfoModal";
import { ToastContainer } from "react-toastify";
import ChamberMemberInfoModal from "../../common/ConfirmationModal/ChamberMemberInfoModal";
import { CSVLink } from "react-csv";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import LogoPicker from "../../common/utilities/LogoPicker";
import ProvincePicker from "../../common/utilities/ProvincePicker";
import PhoneNumberFormat from "../../common/utilities/PhoneNumberFormat";
import ChamberSubscriptionDatePicker from "../../common/utilities/ChamberSubscriptionDatePicker";
import MaxTwoLinksAllowedForChamber, {
  isThereTwoValidLinksInTheProvidedLinks,
} from "../../common/MaxTwoLinksAllowedForChamber";
import { validWebsiteRegEx } from "../../common/utilities/regexes";

const CHARACTER_LIMIT_DESCRIPTION = 2000;

const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "75px",
      height: "75px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100px",
      height: "100px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "65px",
      height: "65px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90px",
      height: "90px",
    },
  },
  chamberTitle: {
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },

  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    paddingBottom: "33px",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  chamberInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  selectedChamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    border: "1px solid black",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
    "& .makeStyles-selectedValue-57 .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "10px",
    },
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "10px",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 14px 14px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "14px 0px 0px 14px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  companyInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  companyDescription: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  companyDescriptionInput: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "0px",
  },
}));
const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
const CreateChamberAdmin = (): ReactElement => {
  const classes = useStyles();

  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customError, setCustomError] = useState(
    "An Error Occured, Please try again."
  );
  const [invalidError, setInvalidError] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [chamberName, setChamberName] = useState("");
  const [loading, setLoading] = useState(false);
  const chamberIds = [];
  let createButtonText = "Create";
  const [open, setOpen] = useState(false);
  const [createChambers] = useCreateChambersMutation();

  const id = uuidv4();
  chamberIds.push(id);

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    numberOfMembersFromInitialSubscriptionAgreement: yup
      .number()
      .min(1, "Number of members must be greater than 0")
      .required("Number of members is required"),
    subscriptionDate: yup
      .string()
      .required("Subscription Start date is required"),
    website: yup
      .string()
      .matches(validWebsiteRegEx, "Enter a valid website")
      .required("Website link is required"),
    supportContactEmail: yup
      .string()
      .email("Not a valid email")
      .required("Support email is required"),
    supportLink: yup
      .string()
      .matches(validWebsiteRegEx, "Enter a valid website"),
    address: yup.object({
      province: yup.string().required("Province is required"),
      city: yup.string().required("City is required"),
      postalCode: yup.string().required("Postal/Zip Code is required"),
      streetAddress: yup.string().required("Street Address is required"),
    }),
    adminConsoleLoginEmail: yup
      .string()
      .email("Not a valid email")
      .required("Dashboard email username is required"),
    jobPostingLink: yup
      .string()
      .matches(validWebsiteRegEx, "Enter a valid website"),
    eventsLink: yup
      .string()
      .matches(validWebsiteRegEx, "Enter a valid website"),
    newsletterLink: yup
      .string()
      .matches(validWebsiteRegEx, "Enter a valid website"),
    surveyLink: yup
      .string()
      .matches(validWebsiteRegEx, "Enter a valid website"),
  });
  const initialValues: ChamberInput = {
    name: "",
    description: "",
    numberOfMembersFromInitialSubscriptionAgreement: 0,
    subscriptionDate: "",
    website: "",
    supportContactEmail: "",
    supportContactName: "",
    supportLink: "",
    phoneNumber: "",
    facebook: "",
    instagram: "",
    threads: "",
    linkedin: "",
    twitter: "",
    tiktok: "",
    youtube: "",
    address: {
      city: "",
      postalCode: "",
      province: "",
      streetAddress: "",
    },
    eventsLink: "",
    newsletterLink: "",
    surveyLink: "",
    logo: undefined,
    id: id,
    jobPostingLink: "",
    adminConsoleLoginEmail: "",
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const redirectToManageChamber = useRedirect(RedirectUri.listAllChamberAdmin);
  const yesButtonAction = useCallback(async (): Promise<void> => {
    setLoading(true);
    setOpen(false);
    redirectToManageChamber();
  }, [redirectToManageChamber]);

  const buttonConfig: YesNoButtonConfig = useMemo(() => {
    return {
      buttonType: ButtonConfigType.YesNoConfig,
      noButtonAction: handleClose,
      yesButtonAction,
    };
  }, [yesButtonAction]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: ChamberInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);
        const chamberAdminUser = await createChambers({
          variables: {
            input: {
              data: [
                {
                  address: {
                    city: input.address.city,
                    postalCode: input.address.postalCode,
                    province: input.address.province,
                    streetAddress: input.address.streetAddress,
                  },
                  eventsLink: input.eventsLink,
                  jobPostingLink: input.jobPostingLink,
                  newsletterLink: input.newsletterLink,
                  surveyLink: input.surveyLink,
                  name: input.name,
                  description: input.description,
                  numberOfMembersFromInitialSubscriptionAgreement:
                    input.numberOfMembersFromInitialSubscriptionAgreement,
                  subscriptionDate: input.subscriptionDate,
                  supportContactEmail: input.supportContactEmail
                    .trim()
                    .toLowerCase(),
                  supportContactName: input.supportContactName,
                  supportLink: input.supportLink,
                  phoneNumber: input.phoneNumber,
                  facebook: input.facebook,
                  instagram: input.instagram,
                  threads: input.threads,
                  linkedin: input.linkedin,
                  twitter: input.twitter,
                  tiktok: input.tiktok,
                  youtube: input.youtube,
                  website: input.website,
                  logo: input.logo,
                  id: id,
                  // adding ignore here cuz adminConsoleLoginEmail will ALWAYS be given on creation
                  // @ts-ignore
                  adminConsoleLoginEmail: input.adminConsoleLoginEmail
                    .trim()
                    .toLowerCase(),
                },
              ],
            },
          },
          refetchQueries: ["getAllChambers"],
        });
        const creationResult = chamberAdminUser.data?.createChambers.results[0];
        if (creationResult?.chamberAddedSuccessfully === false) {
          setInvalidError(true);
          setCustomError(
            `Unable to create chamber. ${creationResult.failureReason}`
          );
          createButtonText = "Error";
          setLoader(false);
          setOpen(false);
          setButtonDisabled(false);
          return;
        }
        const passwordVal = creationResult?.password as string;
        const usernameVal = creationResult?.email as string;
        const chamberNameVal = input.name;
        setPassword(passwordVal);
        setUsername(usernameVal);
        setChamberName(chamberNameVal);
        setOpen(true);
        setLoader(false);
        createButtonText = "Created";
        const button = document.getElementById("csv-download-button");
        button?.click();
      } catch (error) {
        setOpen(false);
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        createButtonText = "Error";
      }
    },
  });
  const handleCreateBase64 = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);
      formik.setFieldValue("logo", base64);
    },
    [formik]
  );
  const deleteLogo = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    formik.setFieldValue("logo", undefined);
  };

  const csvData = [
    ["Dashboard Email Username", "Dashboard Temporary Password"],
    [username, password],
  ];

  const rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.RootAdmin][RedirectUri.createAChamber];
  return (
    <span>
      <div className={classes.titleContent}>
        <span className={classes.title}>
          {/* @ts-ignore */}
          {rootAdminObj?.title}
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
        </span>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        <div>
          <form
            className={classes.formContainer}
            onSubmit={formik.handleSubmit}
          >
            <>
              <>
                <TextField
                  type="text"
                  label="Chamber/Board Name *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <div className={classes.companyInputWithToolTipContainer}>
                  <FormControl className={classes.companyDescription}>
                    <TextField
                      type="text"
                      label="Chamber/Board Description"
                      className={classes.companyDescriptionInput}
                      maxRows={6}
                      multiline
                      InputProps={{
                        disableUnderline: true,
                        inputProps: {
                          maxLength: CHARACTER_LIMIT_DESCRIPTION,
                        },
                      }}
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </FormControl>
                </div>
                <ChamberSubscriptionDatePicker
                  formikSetFieldValue={formik.setFieldValue}
                />
                <TextField
                  type="number"
                  label="Number of Members in First Subscription Agreement *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="numberOfMembersFromInitialSubscriptionAgreement"
                  name="numberOfMembersFromInitialSubscriptionAgreement"
                  value={
                    formik.values
                      .numberOfMembersFromInitialSubscriptionAgreement
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "numberOfMembersFromInitialSubscriptionAgreement",
                      parseInt(e.target.value, 10)
                    );
                    console.log(typeof parseInt(e.target.value, 10));
                  }}
                  error={
                    formik.touched
                      .numberOfMembersFromInitialSubscriptionAgreement &&
                    Boolean(
                      formik.errors
                        .numberOfMembersFromInitialSubscriptionAgreement
                    )
                  }
                  helperText={
                    formik.touched
                      .numberOfMembersFromInitialSubscriptionAgreement &&
                    formik.errors
                      .numberOfMembersFromInitialSubscriptionAgreement
                  }
                />
                <TextField
                  type="text"
                  label="Website *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="website"
                  name="website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                  helperText={formik.touched.website && formik.errors.website}
                />
                <TextField
                  type="text"
                  label="Dashboard Email Username *"
                  className={classes.companyInput}
                  variant="outlined"
                  id="adminConsoleLoginEmail"
                  name="adminConsoleLoginEmail"
                  value={formik.values.adminConsoleLoginEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.adminConsoleLoginEmail &&
                    Boolean(formik.errors.adminConsoleLoginEmail)
                  }
                  helperText={
                    formik.touched.adminConsoleLoginEmail &&
                    formik.errors.adminConsoleLoginEmail
                  }
                />
                <TextField
                  type="text"
                  label="Support Contact Name *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="supportContactName"
                  name="supportContactName"
                  value={formik.values.supportContactName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.supportContactName &&
                    Boolean(formik.errors.supportContactName)
                  }
                  helperText={
                    formik.touched.supportContactName &&
                    formik.errors.supportContactName
                  }
                />
                <TextField
                  type="text"
                  label="Support Contact Email *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="supportContactEmail"
                  name="supportContactEmail"
                  value={formik.values.supportContactEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.supportContactEmail &&
                    Boolean(formik.errors.supportContactEmail)
                  }
                  helperText={
                    formik.touched.supportContactEmail &&
                    formik.errors.supportContactEmail
                  }
                />
                <TextField
                  type="text"
                  label="Support Page URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="supportLink"
                  name="supportLink"
                  value={formik.values.supportLink}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.supportLink &&
                    Boolean(formik.errors.supportLink)
                  }
                  helperText={
                    formik.touched.supportLink && formik.errors.supportLink
                  }
                />
                <PhoneNumberFormat
                  formikErrors={formik.errors.phoneNumber}
                  formikVal={formik.values.phoneNumber}
                  handleChange={formik.handleChange}
                  label="Phone Number"
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
                <TextField
                  type="text"
                  label="Street Address *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="address.streetAddress"
                  name="address.streetAddress"
                  value={formik.values.address?.streetAddress}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address?.streetAddress &&
                    Boolean(formik.errors.address?.streetAddress)
                  }
                  helperText={
                    formik.touched.address?.streetAddress &&
                    formik.errors.address?.streetAddress
                  }
                />
                <ProvincePicker
                  formikVal={formik.values.address?.province}
                  formikErrors={
                    formik.touched.address?.province &&
                    Boolean(formik.errors.address?.province)
                  }
                  formikStringError={formik.errors.address?.province}
                  formikSetFieldValue={formik.setFieldValue}
                />
                <TextField
                  type="text"
                  label="City *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="address.city"
                  name="address.city"
                  value={formik.values.address?.city}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address?.city &&
                    Boolean(formik.errors.address?.city)
                  }
                  helperText={
                    formik.touched.address?.city && formik.errors.address?.city
                  }
                />
                <TextField
                  type="text"
                  label="Postal/Zip Code *"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="address.postalCode"
                  name="address.postalCode"
                  value={formik.values.address?.postalCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address?.postalCode &&
                    Boolean(formik.errors.address?.postalCode)
                  }
                  helperText={
                    formik.touched.address?.postalCode &&
                    formik.errors.address?.postalCode
                  }
                />
                <TextField
                  type="text"
                  label="Facebook Business Page URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="facebook"
                  name="facebook"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.facebook && Boolean(formik.errors.facebook)
                  }
                  helperText={formik.touched.facebook && formik.errors.facebook}
                />
                <TextField
                  type="text"
                  label="Instagram Account URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="instagram"
                  name="instagram"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.instagram && Boolean(formik.errors.instagram)
                  }
                  helperText={
                    formik.touched.instagram && formik.errors.instagram
                  }
                />
                <TextField
                  type="text"
                  label="Threads Account URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="threads"
                  name="threads"
                  value={formik.values.threads}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.threads && Boolean(formik.errors.threads)
                  }
                  helperText={formik.touched.threads && formik.errors.threads}
                />
                <TextField
                  type="text"
                  label="LinkedIn Page URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="linkedin"
                  name="linkedin"
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.linkedin && Boolean(formik.errors.linkedin)
                  }
                  helperText={formik.touched.linkedin && formik.errors.linkedin}
                />
                <TextField
                  type="text"
                  label="Twitter Account URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="twitter"
                  name="twitter"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.twitter && Boolean(formik.errors.twitter)
                  }
                  helperText={formik.touched.twitter && formik.errors.twitter}
                />
                <TextField
                  type="text"
                  label="TikTok Account URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="tiktok"
                  name="tiktok"
                  value={formik.values.tiktok}
                  onChange={formik.handleChange}
                  error={formik.touched.tiktok && Boolean(formik.errors.tiktok)}
                  helperText={formik.touched.tiktok && formik.errors.tiktok}
                />
                <TextField
                  type="text"
                  label="Youtube Account URL"
                  className={classes.companyInput}
                  variant="outlined"
                  id="youtube"
                  name="youtube"
                  value={formik.values.youtube}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.youtube && Boolean(formik.errors.youtube)
                  }
                  helperText={formik.touched.youtube && formik.errors.youtube}
                />
                <TextField
                  type="text"
                  label="Job Posting Link"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="jobPostingLink"
                  name="jobPostingLink"
                  value={formik.values.jobPostingLink}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.jobPostingLink &&
                    Boolean(formik.errors.jobPostingLink)
                  }
                  disabled={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.eventsLink || "",
                      formik.values.newsletterLink || "",
                      formik.values.surveyLink || "",
                    ],
                  })}
                  helperText={
                    formik.touched.jobPostingLink &&
                    formik.errors.jobPostingLink
                  }
                />
                <MaxTwoLinksAllowedForChamber
                  formLabel={"Job Posting Link"}
                  show={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.eventsLink || "",
                      formik.values.newsletterLink || "",
                      formik.values.surveyLink || "",
                    ],
                  })}
                />
                <TextField
                  type="text"
                  label="Events Link"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="eventsLink"
                  name="eventsLink"
                  value={formik.values.eventsLink}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.eventsLink &&
                    Boolean(formik.errors.eventsLink)
                  }
                  disabled={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.jobPostingLink || "",
                      formik.values.newsletterLink || "",
                      formik.values.surveyLink || "",
                    ],
                  })}
                  helperText={
                    formik.touched.eventsLink && formik.errors.eventsLink
                  }
                />
                <MaxTwoLinksAllowedForChamber
                  formLabel={"Events Link"}
                  show={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.jobPostingLink || "",
                      formik.values.newsletterLink || "",
                      formik.values.surveyLink || "",
                    ],
                  })}
                />
                <TextField
                  type="text"
                  label="Newsletter Link"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="newsletterLink"
                  name="newsletterLink"
                  value={formik.values.newsletterLink}
                  onChange={formik.handleChange}
                  disabled={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.jobPostingLink || "",
                      formik.values.eventsLink || "",
                      formik.values.surveyLink || "",
                    ],
                  })}
                  error={
                    formik.touched.newsletterLink &&
                    Boolean(formik.errors.newsletterLink)
                  }
                  helperText={
                    formik.touched.newsletterLink &&
                    formik.errors.newsletterLink
                  }
                />
                <MaxTwoLinksAllowedForChamber
                  formLabel={"Newsletter Link"}
                  show={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.jobPostingLink || "",
                      formik.values.eventsLink || "",
                      formik.values.surveyLink || "",
                    ],
                  })}
                />
                <TextField
                  type="text"
                  label="Survey Link"
                  className={classes.chamberInput}
                  variant="outlined"
                  id="surveyLink"
                  name="surveyLink"
                  value={formik.values.surveyLink}
                  onChange={formik.handleChange}
                  disabled={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.jobPostingLink || "",
                      formik.values.eventsLink || "",
                      formik.values.surveyLink || "",
                    ],
                  })}
                  error={
                    formik.touched.surveyLink &&
                    Boolean(formik.errors.surveyLink)
                  }
                  helperText={
                    formik.touched.surveyLink && formik.errors.surveyLink
                  }
                />
                <MaxTwoLinksAllowedForChamber
                  formLabel={"Survey Link"}
                  show={isThereTwoValidLinksInTheProvidedLinks({
                    links: [
                      formik.values.jobPostingLink || "",
                      formik.values.eventsLink || "",
                      formik.values.newsletterLink || "",
                    ],
                  })}
                />
                <LogoPicker
                  logo={formik.values.logo}
                  deleteLogo={deleteLogo}
                  base64={(e: any) => handleCreateBase64(e)}
                />
                <div>
                  {invalidError ? (
                    <div className={classes.invalidError}>{customError}</div>
                  ) : null}
                </div>{" "}
              </>
              <div className={classes.buttonContainers}>
                <CSVLink
                  data={csvData}
                  filename={`${chamberName} Dashboard Credentials.csv`}
                  id="csv-download-button"
                  style={{ display: "none" }}
                />

                <Button
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: "#E1A731",
                    fontSize: "25px",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    width: "200px",
                    marginRight: "25px",
                  }}
                  disabled={buttonDisabled || loader}
                >
                  {loader ? <CircularProgress /> : "Create"}
                </Button>
              </div>
            </>
          </form>
        </div>
      </div>
      {open && (
        <ChamberMemberInfoModal
          open={open}
          loading={loading}
          handleClose={handleClose}
          message="Created successfully. Credentials have been automatically downloaded."
          buttonConfig={buttonConfig}
          password={password}
          username={username}
          companyName={chamberName}
        />
      )}
    </span>
  );
};

export default CreateChamberAdmin;
