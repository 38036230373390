import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  makeStyles,
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  ShopLocalCampaignInput,
  ShopLocalCampaignParticipatingMembersInput,
  useUpdateShopLocalCampaignMutation,
  useGetAllChamberMembersQuery,
  UserType,
  useGetShopLocalCampaignQuery,
  useEndShopLocalCampaignMutation,
  GetAllShopLocalCampaignsForChamberDocument,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import useRedirect from "../../common/Hooks/useRedirect";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import ShopLocalCampaignEndDatePicker from "./ShopLocalCampaignEndDatePicker";
import Loader from "../../common/Loader/Loader";
import ChooseParticipatingMembers, {
  ParticipationCodeExplanationText,
} from "./ChooseParticipatingMembers";
import { useLocation } from "react-router-dom";
import omitDeep from "omit-deep-lodash";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    minHeight: "85vh",
    paddingBottom: "40px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  notificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  descriptionPreviewText: {
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "600px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const validationSchema = yup.object({
  campaignName: yup.string().required("Contest name is required"),
  prizeDescription: yup
    .string()
    .required("Contest prize description is required"),
  participatingMembers: yup
    .array()
    .required()
    .min(1, "At least one participating member company must be selected")
    .required("Participating member companies are required"),
  startDateUtcMilli: yup
    .number()
    .required("Contest start date is required")
    .min(0, "Contest start date is required"),
  endDateUtcMilli: yup
    .number()
    .required("Contest end date is required")
    .min(Date.now(), "Contest end date is required"),
});

const UpdateChamberShopLocalCampaign = (): ReactElement => {
  const classes = useStyles();
  const CHARACTER_LIMIT_DESCRIPTION = 250;

  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customError, setCustomError] = useState(
    "An Error Occured, Please try again."
  );
  const [invalidError, setInvalidError] = useState(false);
  const [updateShopLocalCampaign] = useUpdateShopLocalCampaignMutation();
  const redirectToShopLocalCampaignList = useRedirect(
    RedirectUri.activeChamberShopLocal,
    {
      showToast: true,
      toastText: enumStrings.chamberShopLocal.updateToastText,
    }
  );

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [endCampaignModalOpen, setEndCampaignModalOpen] = useState(false);

  const location = useLocation();
  const shopLocalCampaignId = useMemo(() => {
    return (location.state as { shopLocalCampaignId: string })
      .shopLocalCampaignId;
  }, [location.state]);

  const { data: shopLocalCampaignInfo } = useGetShopLocalCampaignQuery({
    variables: {
      id: shopLocalCampaignId,
    },
  });

  const intitalValuesMemo = useMemo(() => {
    const dataObj = shopLocalCampaignInfo?.getShopLocalCampaign;
    const initialValues: ShopLocalCampaignInput = {
      startDateUtcMilli: dataObj?.startDateUtcMilli || 0,
      endDateUtcMilli: dataObj?.endDateUtcMilli || 0,
      participatingMembers: (dataObj?.participatingMembers || []).map(
        (member) => ({
          id: member.memberId,
        })
      ),
      campaignName: dataObj?.campaignName || "",
      prizeDescription: dataObj?.prizeDescription || "",
    };
    return omitDeep(initialValues, "__typename");
  }, [
    shopLocalCampaignInfo?.getShopLocalCampaign,
  ]) as unknown as ShopLocalCampaignInput;

  const formik = useFormik({
    initialValues: intitalValuesMemo,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (input: ShopLocalCampaignInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);

        const shopLocalCampaignCreation = await updateShopLocalCampaign({
          variables: {
            id: shopLocalCampaignId,
            input: {
              startDateUtcMilli: input.startDateUtcMilli,
              endDateUtcMilli: input.endDateUtcMilli,
              participatingMembers: input.participatingMembers.map(
                (member) => ({ id: member.id })
              ),
              campaignName: input.campaignName,
              prizeDescription: input.prizeDescription,
            },
          },
          refetchQueries: [
            { query: GetAllShopLocalCampaignsForChamberDocument },
            "getAllShopLocalCampaignsForChamber",
          ],
          awaitRefetchQueries: true,
        });
        const updateionResult =
          shopLocalCampaignCreation.data?.updateShopLocalCampaign;
        if (updateionResult?.shopLocalCampaignUpdatedSuccessfully === false) {
          setInvalidError(true);
          setCustomError(`Unable to update shop local contest`);
          setLoader(false);
          setButtonDisabled(false);
          return;
        }

        setLoader(false);
        setUpdateModalOpen(true);
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  const updateChamberShopLocalCampaignObj = useMemo(() => {
    return enumStrings.groups[UserType.ChamberAdmin][
      RedirectUri.updateShopLocal
    ];
  }, []);

  const { data: allChamberMemberData } = useGetAllChamberMembersQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip: !chamberInfo?.id,
    // fetchPolicy: "cache-and-network",
  });

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (
      allChamberMemberData?.getAllChamberMembers &&
      shopLocalCampaignInfo?.getShopLocalCampaign
    ) {
      setShowLoader(false);
    }
  }, [
    allChamberMemberData?.getAllChamberMembers,
    shopLocalCampaignInfo?.getShopLocalCampaign,
  ]);

  const handleChamberMembersChange = useCallback(
    (selectedChamberMembers: ShopLocalCampaignParticipatingMembersInput[]) => {
      formik.setFieldValue("participatingMembers", selectedChamberMembers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {updateModalOpen && (
        <Modal className={classes.modal} open={updateModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between" }}
          >
            <h1 style={{ fontWeight: "bold" }}>Contest Updated Successfully</h1>
            <div
              style={{
                padding: "40px",
                paddingTop: 10,
                paddingBottom: 0,
                textAlign: "left",
                fontSize: "16px",
              }}
            >
              <ParticipationCodeExplanationText />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={() => {
                  redirectToShopLocalCampaignList();
                }}
                variant="contained"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {endCampaignModalOpen && (
        <EndCampaignModal
          campaignId={shopLocalCampaignId}
          closeModal={() => setEndCampaignModalOpen(false)}
        />
      )}
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{updateChamberShopLocalCampaignObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {updateChamberShopLocalCampaignObj.description}
          </span>
          <div style={{ paddingTop: "10px" }} />
        </div>
      </div>
      <div className={classes.content}>
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Loader />
          </div>
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label={enumStrings.chamberShopLocal.label.campaignName}
                className={classes.companyInput}
                variant="outlined"
                id="campaignName"
                name="campaignName"
                value={formik.values.campaignName}
                onChange={formik.handleChange}
                error={
                  formik.touched.campaignName &&
                  Boolean(formik.errors.campaignName)
                }
                helperText={
                  formik.touched.campaignName && formik.errors.campaignName
                }
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.chamberShopLocal.tooltip.campaignName
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <FormControl className={classes.notificationDescriptionInput}>
                <TextField
                  className={classes.selectedValue}
                  maxRows={5}
                  label={enumStrings.chamberShopLocal.label.prizeDescription}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      maxLength: CHARACTER_LIMIT_DESCRIPTION,
                    },
                  }}
                  value={formik.values.prizeDescription}
                  onChange={formik.handleChange}
                  multiline
                  id="prizeDescription"
                  name="prizeDescription"
                />
              </FormControl>
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.chamberShopLocal.tooltip.prizeDescription
                  }
                  showIt
                />
              </div>
            </div>
            <InputLabel style={{ margin: "auto" }}>
              {`${
                (formik.values.prizeDescription || "").length
              }/${CHARACTER_LIMIT_DESCRIPTION} characters remaining`}
            </InputLabel>
            <FormHelperText
              component="legend"
              style={{
                color: "#f44336",
                paddingLeft: "0px",
                paddingTop: "5px",
                textAlign: "center",
              }}
            >
              {formik.touched.prizeDescription &&
                formik.errors.prizeDescription}
            </FormHelperText>
            <div className={classes.descriptionPreviewText}>
              <h3>
                <span style={{ fontWeight: "bold" }}>
                  Preview of prize description in Chamber Perks App™: <br />
                </span>
                "Member company name" is participating in a Shop Local contest
                created by {chamberInfo?.name}, where you can qualify for a
                chance to win{" "}
                <span style={{ fontWeight: "bold" }}>
                  {formik.values.prizeDescription.length > 0
                    ? formik.values.prizeDescription
                    : "enter text above"}
                </span>
                .
              </h3>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <ShopLocalCampaignEndDatePicker
                formikErrors={formik.errors.startDateUtcMilli}
                formikVal={formik.values.startDateUtcMilli}
                formikSetFieldValue={formik.setFieldValue}
                startAfterDate={formik.values.startDateUtcMilli}
                isForStartDate
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.chamberShopLocal.tooltip.startDateUtcMilli
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <ShopLocalCampaignEndDatePicker
                formikErrors={formik.errors.endDateUtcMilli}
                formikVal={formik.values.endDateUtcMilli}
                formikSetFieldValue={formik.setFieldValue}
                disabled={!(formik.values.startDateUtcMilli > 0)}
                startAfterDate={formik.values.startDateUtcMilli}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.chamberShopLocal.tooltip.endDateUtcMilli
                  }
                  showIt
                />
              </div>
            </div>
            <ChooseParticipatingMembers
              selectedChamberMembers={formik.values.participatingMembers}
              handleChamberMembersChange={handleChamberMembersChange}
              allChamberMemberData={allChamberMemberData}
              formikTouchedAvailableToChamberIds={
                formik.touched.participatingMembers
              }
              formikErrorsAvailableToChamberIds={
                formik.errors.participatingMembers
              }
            />
            <br />
            <br />
            <div>
              {invalidError ? (
                <div className={classes.invalidError}>{customError}</div>
              ) : null}
            </div>{" "}
            {Object.keys(formik?.errors || {}).length > 0 ? (
              <div>
                <div className={classes.invalidError}>
                  Please address any issues in red to update
                </div>
              </div>
            ) : null}
            <br />
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#E1A731",
                fontSize: "23px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: "200px",
                marginTop: "59px",
                marginBottom: "39px",
                margin: "auto",
                height: "65px",
              }}
              disabled={buttonDisabled || loader}
            >
              {loader ? <CircularProgress /> : "Update"}
            </Button>
            <br />
            <Button
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#E1A731",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: "200px",
                marginTop: "59px",
                marginBottom: "39px",
                margin: "auto",
                height: "45px",
              }}
              onClick={() => setEndCampaignModalOpen(true)}
              disabled={buttonDisabled || loader}
            >
              End Campaign
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export const EndCampaignModal = ({
  closeModal,
  campaignId,
}: {
  closeModal: () => void;
  campaignId: string;
}): ReactElement => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);

  const [endCampaignExecution] = useEndShopLocalCampaignMutation();
  const redirectToShopLocalCampaignList = useRedirect(
    RedirectUri.chamberShopLocal,
    {
      showToast: true,
      toastText: "Contest Ended Successfully",
    }
  );
  const endCampaign = useCallback(async () => {
    setLoader(true);
    await endCampaignExecution({
      variables: {
        id: campaignId,
      },
      refetchQueries: [
        { query: GetAllShopLocalCampaignsForChamberDocument },
        "getAllShopLocalCampaignsForChamber",
      ],
      awaitRefetchQueries: true,
    });
    closeModal();
    redirectToShopLocalCampaignList();
  }, [
    campaignId,
    closeModal,
    endCampaignExecution,
    redirectToShopLocalCampaignList,
  ]);

  return (
    <Modal className={classes.modal} open={true}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between", height: "450px" }}
      >
        <h1 style={{ fontWeight: "bold" }}>Are You Sure?</h1>
        <div
          style={{
            padding: "50px",
            paddingBottom: 0,
            paddingTop: 20,
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          <h3>
            Ending the contest will remove the contest from the Chamber Perks
            App and no additional Chamber Perks App™ users will be able to
            participate in the contest prize draw.
            <br />
            <br />
            This contest cannot be restarted after ending.
            <br />
            <br />
            If you are sure you want to end this contest, click End. Otherwise,
            click Go Back.
          </h3>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.modalButton}
            onClick={() => {
              endCampaign();
            }}
            variant="contained"
            disabled={loader}
          >
            {loader ? <CircularProgress size={28} /> : "End"}
          </Button>
          <Button
            className={classes.modalButton}
            style={{
              backgroundColor: "#37474f",
              color: "#e9e9e9",
            }}
            onClick={closeModal}
            variant="contained"
            disabled={loader}
          >
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateChamberShopLocalCampaign;
