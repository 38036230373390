import { ReactElement } from "react";
import theme from "../../themes";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((themes: any) => ({
  root: {
    marginBottom: "-15px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "255px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "420px",
    },
  },
}));

const MaxTwoLinksAllowedForChamber = ({
  formLabel,
  show,
}: {
  formLabel:
    | "Job Posting Link"
    | "Events Link"
    | "Newsletter Link"
    | "Survey Link";
  show: boolean;
}): ReactElement => {
  const classes = useStyles();

  if (!show) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <p>
        Only up to two of either a job posting, events, newsletter, or survey
        link can be shown at one time in the Chamber Perks App. If you want to
        show {formLabel === "Events Link" ? "an" : "a"}{" "}
        {formLabel.toLowerCase()}, you'll have to remove one of the two links
        you've already provided.
      </p>
    </div>
  );
};

export const isThereTwoValidLinksInTheProvidedLinks = ({
  links,
}: {
  links: string[];
}) => {
  const validLinks = links.filter((link) => link.length > 0);

  return validLinks.length >= 2;
};

export default MaxTwoLinksAllowedForChamber;
