import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import { UserTypeContext } from "../../../UserTypeContext";
import { useLocation } from "react-router-dom";
import {
  BannerInput,
  CategoryAdvertisingBannerReturn,
  GetActiveCategoryAdvertisingBannersForChamberMemberDocument,
  useGetActiveCategoryAdvertisingBannersForChamberMemberQuery,
  UserType,
  useUpdateBannerContentForCategoryAdvertisingBannerMutation,
} from "../../../graphql/types";
import BannerPicker from "../../common/BannerPicker";
import cloneDeep from "lodash.clonedeep";
import omitDeep from "omit-deep-lodash";
import { useGetCategoryIdToHumanReadableName } from "../../common/Hooks/useGetCategoryIdToHumanReadableName";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import BannerPickerAgreementOverlay from "../../common/BannerPickerAgreementOverlay";
import useHandleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement from "../../common/Hooks/useHandleAcceptanceOfChamberMemberCategoryAdvertisingBannerAgreement";
import ChamberMemberMemberProfileAndPerksBannersAgreementModal from "../../common/ChamberMemberMemberProfileAndPerksBannersAgreementModal";
import VideoTutorialButton from "../../common/VideoTutorialButton";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    minHeight: "80vh",
    paddingBottom: 30,
    borderRadius: "16px",
    marginTop: "33px",
    margin: "20px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: 30,
    },
  },
}));

type CustomCategoryAdvertisingBannerReturn = CategoryAdvertisingBannerReturn & {
  humanReadableCategoryName: string;
};

const UniqueBannerPicker = ({
  categoryAdvertisingBanner,
}: {
  categoryAdvertisingBanner: CustomCategoryAdvertisingBannerReturn;
}): ReactElement => {
  const [
    parentOverrideForceMediaUploadModalClosed,
    setParentOverrideForceMediaUploadModalClosed,
  ] = useState(false);

  const [bannerUpdateInProgress, setBannerUpdateInProgress] = useState(false);

  const [updateBannerContentForCategoryAdvertisingBanner] =
    useUpdateBannerContentForCategoryAdvertisingBannerMutation();

  const handleBannerUpdate = useCallback(
    async (
      updatedBanner: BannerInput[],
      isSlidePositioningUpdate?: boolean
    ) => {
      setBannerUpdateInProgress(true);

      await updateBannerContentForCategoryAdvertisingBanner({
        variables: {
          categoryId: categoryAdvertisingBanner?.categoryId || "",
          banner: omitDeep(updatedBanner, [
            "__typename",
            "waitingForApprovalByChamberAdmin",
          ]) as BannerInput[],
        },
        refetchQueries: [
          {
            query: GetActiveCategoryAdvertisingBannersForChamberMemberDocument,
          },
        ],
        awaitRefetchQueries: true,
      });

      setBannerUpdateInProgress(false);
      setParentOverrideForceMediaUploadModalClosed(true);

      toast.success(
        isSlidePositioningUpdate
          ? "Updated Successfully"
          : "Banner content sent for approval"
      );
    },
    [
      categoryAdvertisingBanner?.categoryId,
      updateBannerContentForCategoryAdvertisingBanner,
    ]
  );

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const {
    chamberMembeCategoryAdvertisingBannerAgreementIsAccepted,
    handleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement,
  } = useHandleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement({
    categoryId: categoryAdvertisingBanner.categoryId,
    chamberMemberHasAcceptedAgreement:
      categoryAdvertisingBanner?.bannerSettings?.activationInfo
        ?.chamberMemberHasAcceptedAgreement,
  });

  const [
    isCategoryAdvertisingBannerAgreementModalOpen,
    setIsCategoryAdvertisingBannerAgreementModalOpen,
  ] = useState(false);

  const openChamberMemberCategoryAdvertisingBannerAgreementModal =
    useCallback(() => {
      setIsCategoryAdvertisingBannerAgreementModalOpen(true);
    }, []);

  return (
    <div
      style={{
        marginTop: 30,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <ChamberMemberMemberProfileAndPerksBannersAgreementModal
        open={isCategoryAdvertisingBannerAgreementModalOpen}
        handleClose={() =>
          setIsCategoryAdvertisingBannerAgreementModalOpen(false)
        }
        handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement={
          handleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement
        }
        chamberName={chamberInfo?.name || ""}
        isCategoryBanner
      />
      <BannerPickerAgreementOverlay
        chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted={
          chamberMembeCategoryAdvertisingBannerAgreementIsAccepted
        }
        openChamberMemberMemberProfileAndPerksBannersAgreementModal={
          openChamberMemberCategoryAdvertisingBannerAgreementModal
        }
        paddingLeftPx={30}
        userType={UserType.ChamberMemberAdmin}
      />
      <BannerPicker
        disabled={!chamberMembeCategoryAdvertisingBannerAgreementIsAccepted}
        chamberName={chamberInfo?.name || ""}
        key={`bannerPicker_${categoryAdvertisingBanner.categoryId}`}
        pickerType="categoryAdvertisingChamberMemberAdmin"
        formikVal={
          categoryAdvertisingBanner?.bannerSettings?.activationInfo?.banner
            ? cloneDeep(
                categoryAdvertisingBanner?.bannerSettings?.activationInfo
                  ?.banner
              )
            : null
        }
        formikSetFieldValue={handleBannerUpdate}
        pickerLabel={`${categoryAdvertisingBanner.humanReadableCategoryName} Advertising Banners`}
        bannerUpdateInProgress={bannerUpdateInProgress}
        parentOverrideForceMediaUploadModalClosed={
          parentOverrideForceMediaUploadModalClosed
        }
        setParentOverrideForceMediaUploadModalClosed={
          setParentOverrideForceMediaUploadModalClosed
        }
      />
      <HoverToolTip
        description={`These banners will be shown when the ${
          categoryAdvertisingBanner.humanReadableCategoryName
        } category page is opened in the Chamber Perks App™. \n\nFor best quality of both videos and images, ${
          enumStrings.bannerPicker.minSizeRequirementForBanner
            .charAt(0)
            .toLowerCase() +
          enumStrings.bannerPicker.minSizeRequirementForBanner.slice(1)
        } are recommended.\n\nThe position of each banner in Chamber Perks App™ will be the same as shown here.\n\nClick the right and left arrows under each banner to change its positioning.`}
        showIt
      />
    </div>
  );
};

const ManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBannersChamberMember =
  (): ReactElement => {
    const classes = useStyles();

    const {
      state: { userType },
    } = useContext(UserTypeContext);

    const location = useLocation();

    const rootAdminObj = // @ts-ignore
      enumStrings?.groups[userType][
        RedirectUri.chamberEngageManageCategoryAdvertisingBannersChamberMember
      ];

    const [isLoading, setIsLoading] = useState(true);

    const { data: getActiveCategoryAdvertisingBannersForChamberMember } =
      useGetActiveCategoryAdvertisingBannersForChamberMemberQuery();

    const [enabledCatBanner, setEnabledCatBanner] = useState<
      CustomCategoryAdvertisingBannerReturn[]
    >([]);

    const { categoryIdToHumanReadableName } =
      useGetCategoryIdToHumanReadableName();

    useEffect(() => {
      if (
        !getActiveCategoryAdvertisingBannersForChamberMember ||
        !categoryIdToHumanReadableName
      ) {
        return;
      }

      const res = [];
      for (const catBanner of getActiveCategoryAdvertisingBannersForChamberMember?.getActiveCategoryAdvertisingBannersForChamberMember ||
        []) {
        res.push({
          ...catBanner,
          humanReadableCategoryName:
            categoryIdToHumanReadableName[catBanner.categoryId],
        });
      }

      setEnabledCatBanner(res as CustomCategoryAdvertisingBannerReturn[]);

      setIsLoading(false);
    }, [
      categoryIdToHumanReadableName,
      getActiveCategoryAdvertisingBannersForChamberMember,
    ]);

    return (
      <div>
        <div className={classes.titleContent}>
          <div
            className={classes.title}
            style={{
              flexDirection: "column",
            }}
          >
            <span>
              {rootAdminObj.title}
              {(location?.state as any)?.chamberName &&
                ` for ${(location?.state as any)?.chamberName}`}
            </span>
            <span
              style={{
                marginLeft: "1px",
                fontWeight: "normal",
                fontSize: "16px",
              }}
            >
              {rootAdminObj.description}
            </span>
            <div style={{ height: 10 }} />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 1 }} />
              <div style={{ paddingBottom: "10px" }}>
                <VideoTutorialButton
                  label="How to Upload Category Advertising Banners"
                  tutorialUrl="https://share.synthesia.io/embeds/videos/fe255c22-da44-440d-b8d4-c175bc287a23"
                />
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
        </div>
        <div className={classes.content}>
          {isLoading ? (
            <Loader />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 40,
              }}
            >
              {enabledCatBanner.map((catBanner) => (
                <UniqueBannerPicker
                  key={catBanner.categoryId}
                  categoryAdvertisingBanner={catBanner}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

export default ManageAllCategoryAdvertisingBannersForChammberCategoryAdvertisingBannersChamberMember;
