import { Auth } from "aws-amplify";
import { useCallback, useContext } from "react";
import { UserTypeContext } from "../../../UserTypeContext";
import { RedirectUri } from "../../enumStrings";

export const useHandleLogout = () => {
  const {
    setters: { setUserType },
  } = useContext(UserTypeContext);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log(error);
    }

    sessionStorage.clear();
    window.location.href = RedirectUri.login;
    setUserType("");
  }, [setUserType]);

  return handleLogout;
};
