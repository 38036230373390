import React, { useMemo } from "react";
import { Avatar } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LogoImage = ({
  size,
  squared,
  logoSource,
  chamberName,
  maxWidth,
  onClick,
  dontLazyLoad,
}: {
  size: "small" | "smallNormal" | "normal" | "large" | "xl";
  squared?: boolean;
  logoSource?: any;
  chamberName?: string | undefined;
  maxWidth?: boolean;
  onClick?: () => void;
  dontLazyLoad?: boolean;
}) => {
  const avatarSize = useMemo(() => {
    if (size === "small") {
      return 30;
    }

    if (size === "smallNormal") {
      return 45;
    }

    if (size === "normal") {
      return 70;
    }

    if (size === "large") {
      return 90;
    }

    // default size is large
    return 120;
  }, [size]);

  return (
    <div>
      {logoSource ? (
        <div
          onClick={onClick}
          style={{
            cursor: onClick ? "pointer" : "default",
            borderRadius: squared ? 0 : avatarSize / 2,
            padding: squared ? 0 : 20,
            height: avatarSize,
            width: maxWidth ? "100%" : avatarSize,
            overflow: "hidden",
          }}
        >
          {dontLazyLoad ? (
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt=""
              src={logoSource}
            />
          ) : (
            <LazyLoadImage
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt=""
              src={logoSource}
            />
          )}
        </div>
      ) : (
        <Avatar
          style={{
            height: avatarSize,
            width: avatarSize,
            borderRadius: squared ? 0 : avatarSize / 2,
            overflow: "hidden",
          }}
        >
          {chamberName?.charAt(0)}
        </Avatar>
      )}
    </div>
  );
};
export default LogoImage;
