import { useCallback, useEffect, useState } from "react";
import {
  ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
  useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation,
} from "../../../graphql/types";

type StartDateActivationInfo = Pick<
  ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
  "chamberMemberHasAcceptedAgreement"
>;

const useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement =
  ({
    activeMemberProfileAndPerksBannersSettings,
  }: {
    activeMemberProfileAndPerksBannersSettings:
      | { activationInfo: StartDateActivationInfo }
      | undefined
      | null;
  }) => {
    const [
      chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
      setChamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
    ] = useState(false);

    useEffect(() => {
      if (
        activeMemberProfileAndPerksBannersSettings?.activationInfo
          ?.chamberMemberHasAcceptedAgreement
      ) {
        setChamberMemberMemberProfileAndPerksBannersAgreementIsAccepted(true);
      }
    }, [
      activeMemberProfileAndPerksBannersSettings?.activationInfo
        ?.chamberMemberHasAcceptedAgreement,
    ]);

    const [
      acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember,
    ] =
      useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation();

    const handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement =
      useCallback(async () => {
        acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember(
          {
            refetchQueries: [
              "getAllDiscountsFromChamberId",
              "getAllDiscountsFromChamberMemberId",
            ],
          }
        );

        setChamberMemberMemberProfileAndPerksBannersAgreementIsAccepted(true);
      }, [
        acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember,
      ]);

    return {
      chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
      handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement,
    };
  };

export default useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement;
