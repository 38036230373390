import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  GetPerkPartnerDiscountStatsForRootAdminDocument,
  GetPerkPartnerDiscountStatsForRootAdminQuery,
  useGetAllPerkPartnerDiscountsQuery,
  UserType,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader/Loader";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import FullPagePerkPartnerDiscountTable, {
  AllTablePerkPartnerDiscountDataType,
} from "../../common/FullPagePerkPartnerDiscountTable";
import { getApolloContext } from "@apollo/client";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    marginTop: "33px",
    flexDirection: "column",
  },
}));

const RootAdminViewPerkPartners = (): ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const bingo = useMemo(() => {
    return (location.state || { showToast: undefined }) as {
      showToast: boolean | undefined;
    };
  }, [location.state]);
  useEffect(() => {
    if (bingo.showToast) {
      toast.success(enumStrings.toastPerkText);
    }
  }, [bingo.showToast]);

  let { data: perkPartnerDiscount } = useGetAllPerkPartnerDiscountsQuery({
    fetchPolicy: "cache-and-network",
  });

  const [allPerkPartnerDiscountData, setAllPerkPartnerDiscountData] =
    React.useState<AllTablePerkPartnerDiscountDataType>([]);

  const apolloClient = React.useContext(getApolloContext());

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPerkPartnerDiscountStats = async () => {
      if (!perkPartnerDiscount?.getAllPerkPartnerDiscounts) {
        setAllPerkPartnerDiscountData([]);
        return;
      }

      const allDiscountsArr: AllTablePerkPartnerDiscountDataType = [];

      for (const discountData of perkPartnerDiscount?.getAllPerkPartnerDiscounts) {
        const discountStatsData = (await apolloClient.client?.query({
          query: GetPerkPartnerDiscountStatsForRootAdminDocument,
          variables: {
            id: discountData?.id || "",
          },
          fetchPolicy: "cache-first",
        })) as {
          data: GetPerkPartnerDiscountStatsForRootAdminQuery | undefined;
        };

        allDiscountsArr.push({
          ...discountData,
          numberOfChambersAvailableTo: (
            discountData.availableToChamberIds || []
          ).length,
          views:
            discountStatsData?.data?.getPerkPartnerDiscountStats
              ?.totalViewCount || 0,
          opens:
            discountStatsData?.data?.getPerkPartnerDiscountStats
              ?.totalOpenCount || 0,
          redeems:
            discountStatsData?.data?.getPerkPartnerDiscountStats
              ?.totalRedeemCount || 0,
        });
      }

      setAllPerkPartnerDiscountData(
        allDiscountsArr.slice().sort((a, b) => (a.title > b.title ? 1 : -1)) ||
          []
      );
      setIsLoading(false);
    };

    getPerkPartnerDiscountStats();
  }, [perkPartnerDiscount?.getAllPerkPartnerDiscounts, apolloClient.client]);

  const rootAdminObj = // @ts-ignore
    enumStrings?.groups[UserType.RootAdmin][RedirectUri.listAllPerkPartner];

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {allPerkPartnerDiscountData &&
            allPerkPartnerDiscountData.length > 0 ? (
              <FullPagePerkPartnerDiscountTable
                allPerkPartnerDiscountData={allPerkPartnerDiscountData}
              />
            ) : (
              <div
                style={{
                  color: "black",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                No perk partner created
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RootAdminViewPerkPartners;
